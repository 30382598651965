<template>
    <v-container>

        <br>
        <br>

        <div :class="image">
            <v-img contain max-height="100" :src="ErrorImage" max-width="100" style="margin:auto;" />
        </div>

        <br>
        <br>

        <div :class="image">
            <div class="text-center">
                <label
                    style="font-family: 'Arial', sans-serif; font-style: normal; font-weight: 400; font-size: 20px; line-height: 20px; text-align: center; color: #232323; opacity: 0.75;">
                    Tu transacción no se pudo llevar a cabo
                </label>

                <br>
                <br>

                <label
                    style="font-family: 'Arial', sans-serif; font-style: normal; font-weight: 400; font-size: 15px; line-height: 20px; text-align: center; color: #232323; opacity: 0.75;">
                    Ningún cargo fue realizado en tu tarjeta.
                    Por favor intente nuevamente.
                </label>
            </div>
        </div>

        <br>
        <br>

        <div class="text-center">
            <v-btn style="padding: 12px 15px; gap: 10px; width: 345px; height: 50px; background: rgba(255,125,0,1); box-shadow: 4px 8px 24px rgba(51, 51, 204, 0.12);
                        border-radius: 12px; text-transform:inherit; color: white;" elevation="0"
                @click="returnPage()">
                <v-icon color="white" dark left>
                    mdi-arrow-left
                </v-icon>
                Intentar nuevamente
            </v-btn>
        </div>

        <br>

    </v-container>
</template>

<script>
import ErrorImage from "@/assets/images/EXE.png";
import CryptoJS from 'crypto-js';

export default {
    data() {
        return {
            secretKey: process.env.VUE_APP_CRYPT,
            image: null,
            ErrorImage,
            screen: window.screen.width,
            redirectionPath: '',
            servicesData: {
                loadSoap: false,
                loadPlans: false,
                newCar: false,
                keygen: null,
                token: null,
                priceTotal: 0,
                priceDonation: 0,
                priceFinal: 0,
                entityDonation: null,
                secretDonation: null,
                rutPropietario: null,
                e1: 1,
                typePlan: null,
                typeCliente: null,
                loadInitial: false,
            },
        }
    },
    async mounted() {

        const servicesDataAux = localStorage.getItem('servicesData');

        if (servicesDataAux) {
            const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
            const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
            this.servicesData = JSON.parse(decryptedServicesData);
            // console.log("servicesData: ", this.servicesData);

            this.redirectionPath = this.servicesData.typeCliente;
        } else {
            this.redirectionPath = 'clientes';
        }


        localStorage.removeItem('carData');
        localStorage.removeItem('planData');
        localStorage.removeItem('propietarioData');
        localStorage.removeItem('servicesData');
        localStorage.removeItem('arrayShoppingCart');

        if (this.screen > 767) {
            this.image = 'image'
        } else {
            this.image = 'image2'
        }
    },
    methods: {
        returnPage() {
            window.location.href = `/${this.redirectionPath}/inicio`
            // this.$router.push({ path: `/${this.redirectionPath}/inicio` });
        }
    }

}
</script>

<style lang="css" scoped>
.v-alert {
    position: fixed;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%, 100%);
    margin: 0 auto;
}

.image {
    width: 30%;
    margin: auto;
}

.image2 {
    width: 100%;
    margin: auto;
}
</style>
