<template>
  <v-container :class="fullContainer">

    <div class="text-center">
      <br>
      <b v-if="donate == null" :class="gracias"> ¡Todo listo! Gracias por comprar tu SOAP con nosotros!</b>

      <br><br>

      <b v-if="success == 'true' && donate == null" :class="correo">Tu póliza fue enviada a tu correo electrónico y a tu
        teléfono por Whatsapp</b>

      <b v-if="success == 'false' && donate == null" :class="correo">Tu póliza será enviada en las próximas horas a tu
        correo y por WhatsApp</b>

      <br><br>

      <template v-if="!loadingData">
        <!-- trabaja aki con donaciones -->
        <div :class="[summaryBox, { centered: donate === null }]">
          <div :class="summarySize" style="">
            <br>
            <div v-if="donate != null">
              <b :class="graciasDonate"> ¡Todo listo! Gracias por comprar tu SOAP con nosotros!</b>
              <br>
              <br>
              <b v-if="success == 'true'" :class="correoDonate">Tu póliza fue enviada a tu correo electrónico y a tu
                teléfono por Whatsapp</b>
              <b v-if="success == 'false'" :class="correoDonate">Tu póliza será enviada en las próximas horas a tu
                correo y por WhatsApp</b>
            </div>
            <br v-if="donate != null">
            <v-data-table :footer-props="{ 'items-per-page-text': '' }" :headers="headers" :items="patentes"
              :items-per-page="isMobile ? 1 : 5" sort-by="price" class="elevation-1 table"
              no-data-text="NO HAY REGISTROS ¡AÑADE TU SOAP!">

              <!-- <template v-slot:item.actions="{ item }">
                <v-btn v-if="item.cargaAWS != 'false'" class="btnDownload" id="descarga_5" @click="DescargarArchivo(item)">Descargar</v-btn>
                <v-btn v-if="item.cargaAWS == 'false'" disabled class="btnDownload" @click="DescargarArchivo(item)">Descargar</v-btn>
              </template>

<template v-slot:item.price="{ item }">
                <label style="margin:auto">{{ formatPrice(item.price) }}</label>
              </template> -->

              <template v-slot:item="{ item }">
                <div class="mobile-row" v-if="isMobile">
                  <div v-for="header in headers" :key="header.value" class="mobile-row-item"
                    :class="{ 'highlight-patente-row': header.value === 'patenteNumber' }">
                    <div class="mobile-header">{{ header.text }}</div>
                    <div class="mobile-value">
                      <span v-if="header.value === 'actions'">
                        <v-btn v-if="item.cargaAWS != 'false'" class="btnDownload"
                          @click="DescargarArchivo(item)">Descargar</v-btn>
                        <v-btn v-else disabled class="btnDownload">Descargar</v-btn>
                      </span>
                      <span v-else>{{ item[header.value] }}</span>
                    </div>
                  </div>
                </div>

                <!-- Mantener el formato normal en versión desktop -->
                <tr v-else>
                  <td v-for="header in headers" :key="header.value">
                    <span v-if="header.value === 'actions'">
                      <v-btn v-if="item.cargaAWS != 'false'" class="btnDownload"
                        @click="DescargarArchivo(item)">Descargar</v-btn>
                      <v-btn v-else disabled class="btnDownload">Descargar</v-btn>
                    </span>
                    <span v-else>{{ item[header.value] }}</span>
                  </td>
                </tr>
              </template>

            </v-data-table>
          </div>
          <div v-if="donate != null" :class="donateBox">
            <br>
            <span :class="title1View">Agradecemos tu donación</span>
            <br>
            <div :class="donateDescriptionBox">
              <span :class="donateDescription">{{ selectedDonationText }}</span>
            </div>
            <br>
            <v-img v-if="screen > 767" contain :src="selectedDonationImage" max-width="300" style="margin: auto;margin-bottom: -6%;" />
            <v-img v-else contain :src="selectedDonationImage" max-width="240" style="margin: auto;margin-bottom: -5%;" />
            <br><br>
          </div>
        </div>
        <!-- trabaja aki con donaciones -->

      </template>

      <div v-if="loadingData" class="text-center">
        <br>
        <br>
        <v-progress-circular :size="100" color="rgba(0,105,185, 1)" indeterminate></v-progress-circular><br><br>
        Cargando
      </div>

    </div>

  </v-container>
</template>

<script>
import webPayLogo from "@/assets/images/webpay.png";
import ShieldService from "../services/shield.service";
import swal from 'sweetalert';
import Vue from "vue";
import { VueReCaptcha } from 'vue-recaptcha-v3'
const { VUE_APP_RECAPTCHA3_SITE_KEY } = process.env;
import CryptoJS from 'crypto-js';


import FundacionLasRosasLogo from '@/assets/images/FundacionLasRosasLogo.svg';
import BuinZooLogo from '@/assets/images/BuinZooLogo.svg';
import CareLogo from '@/assets/images/CareLogo.svg';
import BomberosLogo from '@/assets/images/BomberosLogo.svg';

Vue.use(VueReCaptcha, { siteKey: VUE_APP_RECAPTCHA3_SITE_KEY })

export default {
  name: "PlanForm",
  data: () => ({
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    secretKey: process.env.VUE_APP_CRYPT,
    FundacionLasRosasLogo,
    BuinZooLogo,
    CareLogo,
    BomberosLogo,
    success: null,
    servicesData: null,
    isMobile: false,
    screen: window.screen.width,
    descargar: null,
    donate: null,
    donateAmount: 0,
    webPayLogo,
    token: null,
    keygen: null,
    summaryBox: null,
    donateBox: null,
    title1View: null,
    correoDonate: null,
    title2View: null,
    patentes: null,
    gracias: null,
    graciasDonate: null,
    correo: null,
    summarySize: null,
    donateDescriptionBox: null,
    donateDescription: null,
    fullContainer: null,
    selectedDonationText: '',
    selectedDonationImage: '',
    loadingData: true,
    headers: [
      { text: 'Patente', align: 'start', sortable: false, value: 'patenteNumber', align: 'center' },
      { text: 'Tipo de Vehículo', value: 'typeVehicle', align: 'center', sortable: false, },
      { text: 'Modelo', value: 'modelVehicle', align: 'center', sortable: false, },
      // { text: 'Correo', value: 'personEmail', align: 'center', sortable: false, },
      // { text: 'Valor SOAP', value: 'price', align: 'center', sortable: false, },
      { text: 'Descargar', value: 'actions', sortable: false, align: 'center', },
    ],
    donateOptions: [
      { value: 'LasRosas', text: 'Con tu aporte y el de todos, ayudarás a mantener los cuidados de 2.200 personas mayores vulnerables que viven en los 29 hogares a lo largo del país. Gracias por ayudarnos a cuidarlos.', image: FundacionLasRosasLogo },
      { value: 'BuinZoo', text: 'Con tu aporte y el de todos, se realizarán iniciativas de conservación para el cuidado de especies en peligro y programas de mejoramiento para la óptima salud de los animales.', image: BuinZooLogo },
      { value: 'donationCare', text: 'Con tu aporte y el de todos, la fundación oncológica pretende reducir la lista de espera de atención a través de la contratación de asistencia profesional para brindar atención a aquellos pacientes que esperan por tratamiento.', image: CareLogo },
      { value: 'Bomberos', text: 'Con tu aporte y el de todos, estamos apoyando la labor de los más de 50 mil bomberos y bomberas de todo nuestro país.', image: BomberosLogo },
    ],
  }),
  async mounted() {
    // console.log('donate: ',this.donate)
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
    const query = this.$route.query;
    if (this.screen > 767) {
      this.descargar = 'descargar'
      this.gracias = 'gracias'
      this.summaryBox = 'summaryBox'
      this.donateBox = 'donateBox'
      this.title1View = 'title1View'
      this.title2View = 'title2View'
      this.graciasDonate = 'graciasDonate'
      this.summarySize = 'summarySize'
      this.correoDonate = 'correoDonate'
      this.correo = 'correo'
      this.donateDescription = 'donateDescription'
      this.donateDescriptionBox = 'donateDescriptionBox'
      this.fullContainer = 'fullContainer'
    } else if (this.screen <= 767) {
      this.descargar = 'descargar2'
      this.gracias = 'gracias2'
      this.summaryBox = 'summaryBox2'
      this.donateBox = 'donateBox2'
      this.title1View = 'title1View2'
      this.title2View = 'title2View2'
      this.graciasDonate = 'graciasDonate2'
      this.summarySize = 'summarySize2'
      this.correoDonate = 'correoDonate2'
      this.correo = 'correo2'
      this.donateDescription = 'donateDescription2'
      this.donateDescriptionBox = 'donateDescriptionBox2'
      this.fullContainer = 'fullContainer2'
    }
    if (query.reload) {
      swal({
        title: "¡Intente nuevamente!",
        text: "Ahora puedes descargar tu póliza",
        icon: "info",
        button: "Ok",
      })
    }

    setTimeout(() => {
      this.getInfo()
    }, 3000);

  },
  methods: {
    back() {
      this.$emit("back");
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 767;
    },
    validate(plan) {
      this.$emit("next-step", 2, plan);
    },
    async DescargarArchivo(item) {
      if (item.pdf) {
        const url = window.URL.createObjectURL(new Blob([new Uint8Array(item.pdf.data).buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `vehiculo-${item.patenteNumber}.pdf`);
        document.body.appendChild(link);
        link.click();
      } else {

        try {

          // reCaptcha v3 token
          await this.$recaptchaLoaded()
          const tokenAmazonia = await this.$recaptcha('getAmazonia');
          let amazoniaRes = await ShieldService.getAmazonia(item.numeroPoliza, tokenAmazonia);

          if (amazoniaRes.status == 'ok') {
            const byteCharacters = atob(amazoniaRes.data.data);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${item.patenteNumber}-${item.personNames}.pdf`);
            document.body.appendChild(link);

            link.click();
          }

        } catch (error) {
          console.log(error)
        }
      }
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    async getInfo() {
      const query = this.$route.query;
      if (query) {
        if (query.success == 'true' && query.token) {
          this.token = query.token;
          this.success = query.success;
          this.patentes = await ShieldService.getToken(this.token);
          this.donate = this.patentes[0].entityDonation != 'None' ? this.patentes[0].entityDonation : null;
          const selectedOption = this.donateOptions.find(option => option.value === this.donate);
          this.selectedDonationText = selectedOption ? selectedOption.text : '';
          this.selectedDonationImage = selectedOption ? selectedOption.image : '';
          localStorage.removeItem('carData');
          localStorage.removeItem('planData');
          localStorage.removeItem('propietarioData');
          localStorage.removeItem('servicesData');
          localStorage.removeItem('arrayShoppingCart');
          this.loadingData = false;
        } else if (query.success == 'true' && query.keygen) {
          this.keygen = query.keygen;
          this.success = query.success;
          this.patentes = await ShieldService.getKeygen(this.keygen);
          this.donate = this.patentes[0].entityDonation != 'None' ? this.patentes[0].entityDonation : null;
          const selectedOption = this.donateOptions.find(option => option.value === this.donate);
          this.selectedDonationText = selectedOption ? selectedOption.text : '';
          this.selectedDonationImage = selectedOption ? selectedOption.image : '';
          localStorage.removeItem('carData');
          localStorage.removeItem('planData');
          localStorage.removeItem('propietarioData');
          localStorage.removeItem('servicesData');
          localStorage.removeItem('arrayShoppingCart');
          this.loadingData = false;
        } else if (query.success == 'false' && query.token) {
          this.token = query.token;
          this.success = query.success;
          this.patentes = await ShieldService.getToken(this.token);
          this.donate = this.patentes[0].entityDonation != 'None' ? this.patentes[0].entityDonation : null;
          const selectedOption = this.donateOptions.find(option => option.value === this.donate);
          this.selectedDonationText = selectedOption ? selectedOption.text : '';
          this.selectedDonationImage = selectedOption ? selectedOption.image : '';
          localStorage.removeItem('carData');
          localStorage.removeItem('planData');
          localStorage.removeItem('propietarioData');
          localStorage.removeItem('servicesData');
          localStorage.removeItem('arrayShoppingCart');
          this.loadingData = false;
        } else if (query.success == 'false' && query.keygen) {
          this.keygen = query.keygen;
          this.success = query.success;
          this.patentes = await ShieldService.getKeygen(this.keygen);
          this.donate = this.patentes[0].entityDonation != 'None' ? this.patentes[0].entityDonation : null;
          const selectedOption = this.donateOptions.find(option => option.value === this.donate);
          this.selectedDonationText = selectedOption ? selectedOption.text : '';
          this.selectedDonationImage = selectedOption ? selectedOption.image : '';
          localStorage.removeItem('carData');
          localStorage.removeItem('planData');
          localStorage.removeItem('propietarioData');
          localStorage.removeItem('servicesData');
          localStorage.removeItem('arrayShoppingCart');
          this.loadingData = false;
        }

        // if(this.patentes.length != 0) {
        //   localStorage.removeItem('carData');
        //   localStorage.removeItem('planData');
        //   localStorage.removeItem('propietarioData');
        //   localStorage.removeItem('servicesData');
        //   localStorage.removeItem('arrayShoppingCart');
        // }

      }
    }
  }
}
</script>


<style lang="scss" scoped>

.fullContainer{
  justify-content: center; 
  height: 100%; 
  background: #FFFFFF 0% 0% no-repeat padding-box; 
  opacity: 1;
  margin-top: -4%;
}
.fullContainer2{
  justify-content: center; 
  height: 100%; 
  background: #FFFFFF 0% 0% no-repeat padding-box; 
  opacity: 1;
  margin-top: -8%;
}

@media (max-width: 767px) {
  .mobile-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    // border: 1px solid #ccc;
    border-radius: 6px;
    background-color: #fff;
    overflow: hidden;
  }

  .mobile-row-item {
    display: flex;
    justify-content: space-between;
    padding: 8px 10px;
    border-bottom: 1px solid #eee;
  }

  .mobile-row-item:last-child {
    border-bottom: none;
  }

  .mobile-header {
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
  }

  .mobile-value {
    text-align: right;
  }

  .highlight-patente-row {
    background-color: rgba(227, 233, 253, 1);
    font-weight: bold;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
}

.donateDescriptionBox {
  width: 70%;
  margin: auto;
  margin-top: 4%;
}

.donateDescriptionBox2 {
  width: 84%;
  margin: auto;
  margin-top: 4%;
}

.donateDescription {
  line-height: 1.8;
  text-align: center;
  font-size: 18px;
  color: rgba(40, 40, 40, 1);
}

.donateDescription2 {
  text-align: center;
  font-size: 14px;
  color: rgba(40, 40, 40, 1);
  // background-color: yellowgreen;
}

.summarySize {
  width: 60%;
}

@media (min-width: 767px) and (max-width: 1000px) {
  .summarySize {
    width: 100% !important;
  }
}

.summarySize2 {
  width: 100%;
}

.title1View {
  font-family: 'Arial', sans-serif;
  color: rgba(40,40,40,1);
  font-weight: bold;
  font-size: 24px;
}

.title1View2 {
  font-family: 'Arial', sans-serif;
  color: rgba(40,40,40,1);
  font-weight: bold;
  font-size: 18px;
  margin-top: 2%;
}

.title2View {
  margin-left: 1%;
  font-family: 'Arial', sans-serif;
  color: rgba(40,40,40,1);
  font-weight: bold;
  font-size: 24px;
}

.title2View2 {
  font-family: 'Arial', sans-serif;
  color: rgba(40,40,40,1);
  font-weight: bold;
  font-size: 18px;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.donateBox {
  background-color: rgba(231,233,235,1);
  width: 50%;
  border-radius: 6px;
}

.donateBox2 {
  background-color: rgba(231,233,235,1);
  width: 100%;
  border-radius: 6px;
  margin-top: 10%;
}

.summaryBox {
  display: flex;
  gap: 5%;
  margin: auto;
  justify-content: center;
}

.graciasDonate {
  width: 750px;
  height: 29px;
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: -0.36px;
  color: rgba(0, 105, 185, 1);
  opacity: 1;
}

.graciasDonate2 {
  width: 100%;
  // height: 29px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  font-size: 17px;
  color: rgba(0, 105, 185, 1);;
}

.gracias {
  width: 750px;
  height: 29px;
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  font-size: 30px;
  letter-spacing: -0.36px;
  color: rgba(0, 105, 185, 1);;
  opacity: 1;
}

.gracias2 {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  font-size: 17px;
  letter-spacing: -0.36px;
  color: rgba(0, 105, 185, 1);;
}


.correo {
  width: 295px;
  height: 23px;
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-size: 20px;
  letter-spacing: -0.24px;
  color: rgba(0,0,0, 1);
}

.correo2 {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-size: 15px;
  color: rgba(0,0,0, 1);
  margin-top: -6%;
}

.correoDonate {
  width: 295px;
  height: 23px;
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  letter-spacing: -0.24px;
  color: rgba(0,0,0, 1);
  text-align: justify;
}

.correoDonate2 {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-size: 15px;
  color: rgba(0,0,0, 1);
  margin-top: -6%;
}

.email {
  width: 230px;
  height: 23px;
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-size: 20px;
  letter-spacing: 0px;
  color: #D74E80;
  text-transform: uppercase;
}

.preferencia {
  width: 416px;
  height: 17px;
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-size: 15px;
  letter-spacing: 0px;
  color: #5C5C5C;
  opacity: 1;
}

.webpay {
  width: 482px;
  height: 206px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  opacity: 1;
}

.descargar {
  margin-left: 37%;
}

.descargar2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30%;
}

.tableHeaders {
  background-color: #2D387C;
  color: white !important;
  border: 2px solid #2D387C;
}

.text-center {
  text-align: center;
}

.btnDownload {
  background-color: rgba(255,125,0,1) !important;
  color: #FFF;
  border-radius: 6px;
  letter-spacing: 0.8px;
  font-weight: bold;
}

.table {
  border: 1px solid #D5D5D5;
  border-radius: 6px;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style lang="css">
.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  background-color: #2D387C;
  color: white !important;
}
</style>