import axios from 'axios'

const { VUE_APP_BODUM_URL, VUE_APP_SHIELD_URL } = process.env

export default class BodumService {

  static async getTokenPartner() {
    try {
      const TokenPartner = await axios.post(
        `${VUE_APP_BODUM_URL}/cencosud/loginpartner`
      );
      return TokenPartner.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener token partner",
      };
    }
  }

  static async loginsocio(token, username, password) {
    try {

      const loginsocio = await axios.post(
        `${VUE_APP_BODUM_URL}/cencosud/loginsocio`,
        {
          username: username,
          password: password,
          token: token,
        },
      );

      return loginsocio.data;


    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener token socio",
      };
    }
  }

  static async GenerarOPT(tokenpartner, tokensocio) {
    try {
      const GenerarOPT = await axios.post(
        `${VUE_APP_BODUM_URL}/cencosud/generaropt`,
        {
          tokenpartner: tokenpartner,
          tokensocio: tokensocio,
        },
      );
      return GenerarOPT.data;


    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener codigo sms",
      };
    }
  }

  static async PagoPuntos(tokenpartner, tokensocio, codigoOtp, points, externalProductId, keygen, externalId) {
    try {
      
      const PagoPuntos = await axios.post(
        `${VUE_APP_BODUM_URL}/cencosud/canje`,
        {
          tokenpartner: tokenpartner,
          tokensocio: tokensocio,
          externalId: externalId,
          points: points,
          codigoOtp: codigoOtp,
          externalProductId: externalProductId,
        },
      );

      if (PagoPuntos.data.status == 'ok') {

        const shieldRes = await axios.post(
          `${VUE_APP_SHIELD_URL}/cartSoap/setExternalId`,
          {
            externalID: externalId,
            internalID: PagoPuntos.data.message.internalId,
            keygen: keygen
          }

        );

      // console.log("setexternal",shieldRes);
      }
      return PagoPuntos.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al procesar el pago",
      };
    }
  }

  static async consultaPuntos(tokenpartner, rutCliente) {
    try {
      const consultaPuntos = await axios.post(
        `${VUE_APP_BODUM_URL}/cencosud/consultasocio`,
        {
          rut: rutCliente,
          token: tokenpartner,
        },
      );
      return consultaPuntos.data;

    } catch (error) {
      return {
        status: "error",
        message: "rut invalido",
      };
    }
  }

}