<template>
  <div class="containerStyle">

    <div v-if="pathname != `/${this.servicesData.typeCliente}/finish` && !this.pathname.includes('puntos')"
      :class="btnCartButtonView">
      <CarritoCompra id="home_2" :patentes="arrayShoppingCart" :e1="servicesData.e1"></CarritoCompra>
    </div>

    <v-row justify="center"><v-dialog v-model="dialog" max-width="800px" min-height="800px">
        <v-card>
          <v-card-text>
            <div v-if="!loadLogin" class="form-container" style="display: flex;">
              <v-col>
                <br>
                <br>
                <h1 class="titulo text-center"> Inicia tu sesión</h1><br>
                <h2 class="text-center"> Ingresa tu rut y contraseña de Puntos Cencosud</h2><br>
                <br><label>Rut</label>
                <v-form ref="form" v-model="valid">
                  <v-text-field v-model="rutCliente" :rules="rutRules2" label="Ingresa tu rut" required filled solo
                    hide-details="auto" @change="changeRut2">
                  </v-text-field >
                   <br>
                   <label>Contraseña</label>
                  <v-text-field v-model="contrasenaCliente" :rules="contrasenaRules" label="Ingresa tu contraseña"
                    required filled solo hide-details="auto" type="password">
                  </v-text-field>
                </v-form>
                <br><v-btn class="login" @click="logincenco()"> Iniciar sesión </v-btn><br>
                <br><a
                  style="color: rgb(108, 117, 125); text-decoration: none; background-color: transparent;  justify-content: center;font-family: 'Nunito Sans', sans-serif;"
                  href="https://www.puntoscencosud.cl/puntos/recuperar">Quiero recuperar mi contraseña de Puntos
                  Cencosud</a>
              </v-col>
              <v-col v-if="screen > 800">
                <v-img contain :src="cencoLogin" />
              </v-col>
            </div>
            <div v-if="loadLogin" class="text-center">
              <br>
              <br>
              <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
              Cargando
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>

    <v-container>

      <div v-if="screen <= 800 && this.pathname.includes('finish')">
        <!-- <div v-if="screen <= 800"> -->
        <v-img contain :src="BannerFinishMobile" @click="goToLanding"
          style="margin: auto; max-width: 95%; margin-bottom: 27%; margin-top: 3%; border-radius: 6px !important; cursor: pointer;" />
      </div>

      <div v-if="screen > 800 && this.pathname.includes('finish')">
        <!-- <div v-if="screen > 800"> -->
        <v-img contain :src="BannerFinishDesktop" @click="goToLanding"
          style="margin: auto;max-width: 100%;margin-bottom: 4%;margin-top: 1%; border-radius: 18px !important; cursor: pointer;" />
      </div>

      <v-stepper alt-labels v-model="servicesData.e1" elevation="0" :class="steps">

        <v-stepper-header :class="stepperHeaderView">

          <v-stepper-step :complete="servicesData.e1 > 1" step="1"><b :class="stepLabelSelected">Revisa tus
              datos</b></v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="servicesData.e1 > 2" step="2" class="stepLabel"><b
              :class="stepLabelSelected">Pagar</b></v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3" class="stepLabel"><b :class="stepLabelSelected">Recibe e imprime tu
              SOAP</b></v-stepper-step>

        </v-stepper-header>

        <v-stepper-items>

          <v-stepper-content step="1">
            <div v-if="loadSoap" class="text-center">
              <br>
              <br>
              <v-progress-circular :size="100" color="rgba(0,105,185, 1)" indeterminate></v-progress-circular><br><br>
              Cargando
            </div>

            <div v-else>

              <InitForm v-if="loadSoap == false" :carData="carData" :propietarioData="propietarioData"
                :planData="planData" :servicesData="servicesData" :arrayShoppingCart="arrayShoppingCart"
                @calculateFinalPrice="calculateFinalPrice" />

              <br><br>

              <div v-if="servicesData.e1 != 3 && servicesData.e1 != 2" :class="cartBoxView" class="text-center">
                <div :class="btnFindSoapTitleBox">
                  <br v-if="screen <= 767">
                  <b :class="title1View">¿Ya tienes tu SOAP?</b>
                  <b :class="title2View">Descarga tu certificado aquí</b>
                </div>
                <v-btn @click="redirectFindSoap()" :class="btnFindSoapView" id="volver_2">CONSULTA TU SOAP</v-btn>
                <br>
                <br>
              </div>

              <div v-else :class="cartBoxView">
                <div style="width: 70%;">
                  <b :class="title1View">¿Ya tienes tu SOAP?</b>
                  <b :class="title2View">Descarga tu certificado aquí</b>
                </div>
                <v-btn @click="redirectFindSoap()" :class="btnFindSoapView3" id="volver_5">CONSULTA TU SOAP</v-btn>
              </div>

              <br>
            </div>

          </v-stepper-content>

          <v-stepper-content step="2">

            <div v-if="loading" class="text-center">
              <v-progress-circular :size="100" color="rgba(0,105,185, 1)" indeterminate></v-progress-circular><br><br>
              Cargando
            </div>

            <SecForm v-else :carData="carData" :propietarioData="propietarioData" :planData="planData"
              :servicesData="servicesData" :arrayShoppingCart="arrayShoppingCart" :donationData="donationData"
              :calculateFinalPrice="calculateFinalPrice" @getToken="getToken" />

          </v-stepper-content>

          <v-stepper-content step="3">

            <div v-if="loading || loadingFinal" class="text-center">
              <v-progress-circular :size="100" color="rgba(0,105,185, 1)"
                indeterminate></v-progress-circular><br><br><br>
              Estamos procesando tu cotización. . .
            </div>

            <ThirdForm v-else-if="!loading && !loadingFinal" />

            <br>

          </v-stepper-content>

        </v-stepper-items>

      </v-stepper>

      <div v-if="servicesData.e1 == 2 && conditions == true && screen > 767" class="finalPayContainerDesktop">

        <div v-if="loadTokenPayment">

          <br>
          <br>

          <div style="display: flex; justify-content: center; align-items: center;">
            <b class="opcionPago"> Puedes pagar tu SOAP con </b>
          </div>

          <div style="display: flex; justify-content: center; align-items: center;">
            <v-img contain :src="LogoWebpayCards" style="max-width: 300px; height: 135px;" />
          </div>

          <div class="text-center">

            <br>
            <br>
            <v-progress-circular :size="100" color="rgba(0,105,185, 1)" indeterminate></v-progress-circular><br><br>

          </div>

          <br>
          <br>

          <div style="display: flex; justify-content: center; align-items: center;">
            <v-btn disabled class="button1" id="no_3" @click="btnVolver()">Volver </v-btn>
            <br>
            <br>
            <br>

            <form :action="webPayUrl" method="POST" ref="form_payment">
              <input type="hidden" name="token_ws" :value="token" />
              <v-btn disabled class="button2" key="first" type="submit" value="Pagar" @click="obtenerToken"
                id="irAPagar_3">
                Ir a pagar
              </v-btn>
            </form>
          </div>

          <br>
          <br>

        </div>

        <div v-else>

          <br>
          <br>
          <!-- PAGAR CON PUNTOS -->
          <div v-if="this.servicesData.typeCliente == 'puntos' && this.arrayShoppingCart.length == 1">

            <div v-if="status == false">
              <div style="display: grid; justify-content: center; align-items: center; gap: 2%;">
                <b class="opcionPago">Puedes pagar tu SOAP con</b>
                <div style="display: flex;">
                  <!-- <v-img contain :src="LogoWebpayCards" style="max-width: 300px; height: 135px;" /> -->
                  <v-img contain :src="cencoLogo" style="max-width: 300px; height: 135px;" />
                </div>
              </div>

              <!-- Radio para seleccionar el método de pago -->
              <!-- <v-radio-group v-model="selectedPaymentMethod" row style="margin: auto; width: 426px;">
                <v-radio label="" value="tarjeta" style="margin-left: 54px"></v-radio>
                <v-radio label="" value="puntos" style="margin-left: 246px"></v-radio>
              </v-radio-group> -->

              <div style="display: flex; justify-content: center; align-items: center;">
                <v-btn class="button1" id="no_3" @click="btnVolver()">Volver</v-btn>

                <form :action="webPayUrl" method="POST" ref="form_payment">
                  <input type="hidden" name="token_ws" :value="token" />
                  <v-btn class="button2" key="first" type="submit" value="Pagar" @click.prevent="obtenerTokenCenco"
                    id="irAPagar_3">
                    Ir a pagar
                  </v-btn>
                </form>
              </div>
            </div>

            <div v-if="status == true">
              <div class="condicion">
                <!-- <b> Para continuar tu compra y pagar con tus puntos Cencosud, porfavor ingresa el código de
                    seguridad que ha llegado a tu celular
                </b> -->
              </div>

              <div v-if="loading" class="text-center">
                <!-- <br>
              <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
              Cargando -->
              </div>

              <v-col v-if="tokensocio && (loadPuntos == false) && status == true">
                <v-data-table v-if="!loadCanje" :headers="headersCenco" :items="clienteCenco" hide-default-footer
                  class="elevation-1" style=" width: 80%; margin: auto;">
                  <template v-slot:item.opt="{ item }">
                    <br>
                    <v-text-field style="max-width: 100px; margin: auto;" v-if="item.pointsCenco <= item.points"
                      v-model="codigoOpt" required solo :class="labelsSup">
                    </v-text-field>
                    <v-text-field v-if="item.pointsCenco > item.points" disabled filled solo :class="labelsSup"
                      hide-details="auto">
                    </v-text-field>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn v-if="item.pointsCenco <= item.points" :disabled="!codigoOpt"
                      style="background-color: #0069B6; color: white; margin-top: -10%;" @click="canjearpuntos()">
                      Canjear
                    </v-btn>
                    <v-btn v-if="item.pointsCenco > item.points" disabled
                      style="background-color: #0069B6; color: white; margin-top: -10%;" @click="canjearpuntos()">
                      Canjear
                    </v-btn>
                  </template>
                </v-data-table>

              </v-col>

            </div>

          </div>
          <!-- FIN PAGAR CON PUNTOS CLIENTES -->

          <div v-else>
            <div style="display: flex; justify-content: center; align-items: center;gap: 2%;">
              <b class="opcionPago"> Puedes pagar tu SOAP con </b>
              <v-img contain :src="LogoWebpayCards" style="max-width: 300px; height: 135px;" />
            </div>
            <div style="display: flex; justify-content: center; align-items: center;">
              <v-btn class="button1" id="no_3" @click="btnVolver()">Volver </v-btn>
              <br>
              <br>
              <br>

              <form :action="webPayUrl" method="POST" ref="form_payment">
                <input type="hidden" name="token_ws" :value="token" />
                <v-btn class="button2" key="first" type="submit" value="Pagar" @click="obtenerToken" id="irAPagar_3">
                  Ir a pagar
                </v-btn>
              </form>
            </div>
          </div>

          <br>
          <br>

        </div>

      </div>

      <div v-if="servicesData.e1 == 2 && conditions == true && screen <= 767" class="finalPayContainerMovil">

        <div v-if="loadTokenPayment">

          <br>
          <br>

          <div style="display: flex; justify-content: center; align-items: center;">
            <b class="opcionPago"> Puedes pagar tu SOAP con </b>
          </div>

          <div style="display: flex; justify-content: center; align-items: center;">
            <v-img contain :src="LogoWebpayCards" style="max-width: 300px; height: 135px;" />
          </div>

          <div class="text-center">

            <br>
            <br>
            <v-progress-circular :size="100" color="rgba(0,105,185, 1)" indeterminate></v-progress-circular><br><br>

          </div>

          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <v-btn disabled class="button1Mobile" id="no_3" style=" margin-right: 30px" @click="btnVolver()">Volver
            </v-btn>

            <br>

            <form :action="webPayUrl" method="POST" ref="form_payment">
              <input type="hidden" name="token_ws" :value="token" />
              <v-btn disabled class="button2Mobile" id="irAPagar_3" key="first" type="submit" value="Pagar"
                @click="obtenerToken">
                Ir a pagar
              </v-btn>
            </form>
          </div>

          <br>
          <br>

        </div>

        <div v-else>

          <!-- PAGAR CON PUNTOS -->
          <div v-if="this.servicesData.typeCliente == 'puntos' && this.arrayShoppingCart.length == 1">

            <div v-if="status == false">
              <div style="display: grid; justify-content: center; align-items: center; gap: 2%;">
                <b class="opcionPago">Puedes pagar tu SOAP con</b>
                <div style="display: flex; margin: auto;">
                  <!-- <v-img contain :src="LogoWebpayCards" style="max-width: 300px; height: 135px;" /> -->
                  <v-img contain :src="cencoLogo" style="max-width: 300px; height: 135px;" />
                </div>
              </div>

              <!-- Radio para seleccionar el método de pago -->
              <!-- <v-radio-group v-model="selectedPaymentMethod" row style="margin: auto; width: 426px;">
                <v-radio label="" value="tarjeta" style="margin-left: 54px"></v-radio>
                <v-radio label="" value="puntos" style="margin-left: 246px"></v-radio>
              </v-radio-group> -->

              <div style="display: grid; justify-content: center; align-items: center;">
                <v-btn class="button1MobileCenco" id="no_3" @click="btnVolver()">Volver</v-btn>

                <form :action="webPayUrl" method="POST" ref="form_payment">
                  <input type="hidden" name="token_ws" :value="token" />
                  <v-btn class="button2MobileCenco" key="first" type="submit" value="Pagar" @click.prevent="obtenerTokenCenco"
                    id="irAPagar_3">
                    Ir a pagar
                  </v-btn>
                </form>
              </div>
            </div>

            <div v-if="status == true">
              <div class="condicion">
                <!-- <b> Para continuar tu compra y pagar con tus puntos Cencosud, porfavor ingresa el código de
                    seguridad que ha llegado a tu celular
                </b> -->
              </div>

              <div v-if="loading" class="text-center">
                <!-- <br>
              <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
              Cargando -->
              </div>

              <v-col v-if="tokensocio && (loadPuntos == false) && status == true">
                <v-data-table v-if="!loadCanje" :headers="headersCenco" :items="clienteCenco" hide-default-footer
                  class="elevation-1" style=" width: 80%; margin: auto;">
                  <template v-slot:item.opt="{ item }">
                    <br>
                    <v-text-field style="max-width: 100px; margin: auto;" v-if="item.pointsCenco <= item.points"
                      v-model="codigoOpt" required solo :class="labelsSup">
                    </v-text-field>
                    <v-text-field v-if="item.pointsCenco > item.points" disabled filled solo :class="labelsSup"
                      hide-details="auto">
                    </v-text-field>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn v-if="item.pointsCenco <= item.points" :disabled="!codigoOpt"
                      style="background-color: #0069B6; color: white; margin-top: -10%;" @click="canjearpuntos()">
                      Canjear
                    </v-btn>
                    <v-btn v-if="item.pointsCenco > item.points" disabled
                      style="background-color: #0069B6; color: white; margin-top: -10%;" @click="canjearpuntos()">
                      Canjear
                    </v-btn>
                  </template>
                </v-data-table>

              </v-col>

            </div>

          </div>
          <!-- FIN PAGAR CON PUNTOS CLIENTES -->

          <div v-else> 
            <div style="display: flex; justify-content: center; align-items: center;">
              <b class="opcionPago"> Puedes pagar tu SOAP con </b>
            </div>

            <div style="display: flex; justify-content: center; align-items: center;">
              <v-img contain :src="LogoWebpayCards" style="max-width: 300px; height: 135px;" />
            </div>

            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
              <v-btn class="button1Mobile" id="no_3" style=" margin-right: 30px" @click="btnVolver()">Volver </v-btn>

              <br>

              <form :action="webPayUrl" method="POST" ref="form_payment">
                <input type="hidden" name="token_ws" :value="token" />
                <v-btn class="button2Mobile" id="irAPagar_3" key="first" type="submit" value="Pagar"
                  @click="obtenerToken">
                  Ir a pagar
                </v-btn>
              </form>
           </div>
          </div>
          <br>
          <br>

        </div>

      </div>

    </v-container>

  </div>
</template>

<script>
import RutUtils from "../utils/rut.utils";
import Cuotas from "@/assets/images/Cuotas.png";
import webPayLogo from "@/assets/images/webpay.png";
import cencoLogo from "@/assets/images/logocenco.png";
import cencoLogin from "@/assets/images/CencoImage.png";


import BannerClientesMobile from "@/assets/images/BannerClientesMobile.png";
import BannerClientesDesktop from "@/assets/images/BannerClientesDesktop.png";

import BannerNoClientesMobile from "@/assets/images/BannerNoClientesMobile.png";
import BannerNoClientesDesktop from "@/assets/images/BannerNoClientesDesktop.png";

import BannerColaboradoresMobile from "@/assets/images/BannerColaboradoresyPymeMobile.jpg";
import BannerColaboradoresDesktop from "@/assets/images/BannerColaboradoresyPymeDesktop.jpg";

import BannerPymeMobile from "@/assets/images/BannerPuntosMobile.png";
import BannerPymeDesktop from "@/assets/images/BannerPuntosDesktop.png";

import BannerFinishMobile from "@/assets/images/BannerFinishMobile.jpg";
import BannerFinishDesktop from "@/assets/images/BannerFinishDesktop.png";

import LogoWebpayCards from "@/assets/images/Tarjetas_Cencosud.png";

import InitForm from "@/components/InitForm.vue";
import SecForm from "@/components/SecForm.vue";
import ThirdForm from "@/components/ThirdForm.vue";
import CarritoCompra from "@/components/Carrito.vue";

import SoapService from "../services/soap.service";
import ShieldService from "../services/shield.service";
import BodumService from '../services/bodum.service';

import CryptoJS from 'crypto-js';
import swal from 'sweetalert';
import Vue from "vue";
import keygen from 'keygen';

import { VueReCaptcha } from 'vue-recaptcha-v3';
const { VUE_APP_RECAPTCHA3_SITE_KEY } = process.env;

Vue.use(VueReCaptcha, { siteKey: VUE_APP_RECAPTCHA3_SITE_KEY })

export default {
  name: "Form",
  components: {
    InitForm,
    SecForm,
    ThirdForm,
    CarritoCompra
  },
  data() {
    return {
      secretKey: process.env.VUE_APP_CRYPT,
      pathname: window.location.pathname,
      typePlan: null,
      conditions: null,
      webPayLogo,
      cencoLogo,
      cencoLogin,
      LogoWebpayCards,
      BannerFinishMobile,
      BannerFinishDesktop,
      BannerClientesMobile,
      BannerClientesDesktop,
      BannerNoClientesMobile,
      BannerNoClientesDesktop,
      BannerColaboradoresMobile,
      BannerColaboradoresDesktop,
      BannerPymeMobile,
      BannerPymeDesktop,
      token: null,
      title1View: null,
      title2View: null,
      bannerView: null,
      cartBoxView: null,
      btnFindSoapView: null,
      btnCartButtonView: null,
      stepperHeaderView: null,
      stepLabelSelected: null,
      btnFindSoapView3: null,
      btnFindSoapTitleBox: null,
      btnBack: null,
      btnBackMobile: null,


      Cuotas,
      switch: true,
      generaropt: null,
      loadSoap: true,
      keygen: null,
      steps: null,
      screen: window.screen.width,
      loading: false,
      loadingFinal: false,
      patenteData: {},
      patentes: [],
      patenteUtils: null,
      priceTotal: 0,
      typeCliente: null,

      propietarioData: {
        rut: null,
        nombres: null,
        firtlastname: null,
        secondlastname: null,
        email: null,
        phoneNumber: null,
        propietario: true,
        rutPropietario: null,
        remarque: null,
        pricePuntos: null,
        campana: null,
      },

      arrayShoppingCart: [],

      servicesData: {
        loadSoap: false,
        loadPlans: false,
        newCar: false,
        keygen: null,
        token: null,
        priceTotal: 0,
        priceDonation: 0,
        priceFinal: 0,
        entityDonation: null,
        optionDonate: true,
        secretDonation: null,
        rutPropietario: null,
        e1: 1,
        typePlan: null,
        typeCliente: null,
        loadInitial: false,
      },

      carData: {
        id: null,
        patente: null,
        patenteDv: null,
        year: null,
        type: null,
        marca: null,
        model: null,
        motor: null,
        price: null,
        pricePuntos: null,
        externalProductId: null,
      },

      planData: {},

      donationData: [],

      planAuto: null,
      planCamioneta: null,
      planMoto: null,
      planArrastre: null,
      planBus: null,
      plans: {},
      planId: null,
      loadTokenPayment: false,
      loadPlans: true,
      webPayUrl: process.env.VUE_APP_WEBPAY_URL,
      clienteCenco: [],
      CencoSelect: false,
      webPaySelect: false,
      loadLogin: false,
      tokenpartner: null,
      dialog: false,
      respuestaLogin: null,
      newRut2: null,
      rutCliente: null,
      contrasenaCliente: null,
      paymentMethodDisabled: false,
      openDialog: false,
      loadPuntos: false,
      tokensocio: null,
      puntosclientes: null,
      switchFormaPago: false,
      dialogOpt: false,
      status: false,
      loadCanje: false,
      externalId: false,
      validarcanje: null,
      codigoOpt: null,
      selectedPaymentMethod: null,

      headersCenco: [
        { text: 'Nombre Cliente', align: 'start', sortable: false, value: 'name', align: 'center', },
        { text: 'Apellido Cliente', value: 'surname', align: 'center', sortable: false, },
        { text: 'Puntos Cliente', value: 'points', align: 'center', sortable: false, },
        { text: 'Puntos Plan a Contratar', value: 'pointsCenco', align: 'center', sortable: false, },
        { text: 'Código de verificación', value: 'opt', sortable: false, align: 'center', sortable: false, },
        { text: 'Realizar canje', value: 'actions', sortable: false, align: 'center', },
      ],
      rutRules2: [
      (v) => !!v || "Rut es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
      ],
      contrasenaRules: [
        (v) => !!v || "La contraseña es requerida",
        (v) => !/[<>"'`]/.test(v) || "No se permiten caracteres peligrosos como < > \" ' `",
      ],

    };
  },
  async mounted() {
    let path;
    let cliente;

    if (this.$route.path.includes('/general')) {
      path = 'soap-general-cenco';
      cliente = 'general';
      this.BannerFinishMobile = BannerFinishMobile;
      this.BannerFinishDesktop = BannerFinishDesktop;

    } else if (this.$route.path.includes('/clientes')) {
      path = 'soap-clientes-cenco';
      cliente = 'clientes';
      this.BannerFinishMobile = BannerFinishMobile;
      this.BannerFinishDesktop = BannerFinishDesktop;

    } else if (this.$route.path.includes('/colaborador')) {
      path = 'soap-colaborador-cenco';
      cliente = 'colaborador';
      this.BannerFinishMobile = BannerFinishMobile;
      this.BannerFinishDesktop = BannerFinishDesktop;
    } else if (this.$route.path.includes('/puntos')) {
      path = 'soap-puntos-cenco';
      cliente = 'puntos';
      this.BannerFinishMobile = BannerFinishMobile;
      this.BannerFinishDesktop = BannerFinishDesktop;
    }

    const arrayShoppingCartAux = localStorage.getItem('arrayShoppingCart');
    const propietarioDataAux = localStorage.getItem('propietarioData');
    const servicesDataAux = localStorage.getItem('servicesData');
    const carDataAux = localStorage.getItem('carData');
    const planDataAux = localStorage.getItem('planData');
    const donationDataAux = localStorage.getItem('donationData');

    if (arrayShoppingCartAux) {
      const bytesArrayShoppingCart = CryptoJS.AES.decrypt(arrayShoppingCartAux, this.secretKey);
      const decryptedArrayShoppingCart = bytesArrayShoppingCart.toString(CryptoJS.enc.Utf8);
      this.arrayShoppingCart = JSON.parse(decryptedArrayShoppingCart);
      // console.log("arrayShoppingCart: ", this.arrayShoppingCart);

    }

    if (propietarioDataAux) {
      const bytesPropietarioData = CryptoJS.AES.decrypt(propietarioDataAux, this.secretKey);
      const decryptedPropietarioData = bytesPropietarioData.toString(CryptoJS.enc.Utf8);
      this.propietarioData = JSON.parse(decryptedPropietarioData);
      // console.log("propietarioData: ", this.propietarioData);

    }

    if (servicesDataAux) {
      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      // console.log("servicesData: ", this.servicesData);

      const backButton = localStorage.getItem('backButton');

      if (this.servicesData.typePlan != path && backButton != 'SummaryToData') {

        localStorage.removeItem('carData');
        localStorage.removeItem('planData');
        localStorage.removeItem('propietarioData');
        localStorage.removeItem('servicesData');
        localStorage.removeItem('arrayShoppingCart');

        window.location.href = `/${cliente}/inicio`
        // this.$router.push({ path: `/${cliente}/inicio` });
      } else {
        localStorage.removeItem('backButton')
      }

    }

    if (carDataAux) {
      const bytesCarData = CryptoJS.AES.decrypt(carDataAux, this.secretKey);
      const decryptedCarData = bytesCarData.toString(CryptoJS.enc.Utf8);
      this.carData = JSON.parse(decryptedCarData);
      // console.log("carData: ", this.carData);

    }

    if (planDataAux) {
      const bytesPlanData = CryptoJS.AES.decrypt(planDataAux, this.secretKey);
      const decryptedPlanData = bytesPlanData.toString(CryptoJS.enc.Utf8);
      this.planData = JSON.parse(decryptedPlanData);
      // console.log("planData: ", this.planData);

    }

    if (donationDataAux) {
      const bytesDonationData = CryptoJS.AES.decrypt(donationDataAux, this.secretKey);
      const decryptedDonationData = bytesDonationData.toString(CryptoJS.enc.Utf8);
      this.donationData = JSON.parse(decryptedDonationData);
      // console.log("donationData: ", this.donationData);

    }

    this.propietarioData.campana = window.location.href;

    const query = this.$route.query;

    if (query.process == "behind") {
      window.location.href = '/info'
      // this.$router.push({ path: '/info' });
    }

    if (this.screen > 1001) {
      this.steps = 'steps'
      this.bannerView = 'bannerView'
      this.cartBoxView = 'cartBoxView'
      this.btnFindSoapView = 'btnFindSoapView'
      this.stepperHeaderView = 'stepperHeaderView'
      this.stepLabelSelected = 'stepLabelSelected'
      this.btnFindSoapView3 = 'btnFindSoapView3'
      this.title1View = 'title1View'
      this.title2View = 'title2View'
      this.btnFindSoapTitleBox = 'btnFindSoapTitleBox'

      if (this.pathname.includes('datos')) {
        this.btnCartButtonView = 'btnCartButtonViewSummaryDatos'
      } else if (this.pathname.includes('resumen')) {
        this.btnCartButtonView = 'btnCartButtonViewSummaryResumen'
      }

      this.btnBack = 'btnBack'

    } else if (this.screen <= 767) {

      if (this.pathname.includes('datos')) {
        if (this.arrayShoppingCart.length != 0) {
          this.steps = 'stepsDatosCon'
        } else {
          this.steps = 'stepsDatosSin'
        }
        this.btnCartButtonView = 'btnCartButtonViewDatos'
      } else if (this.pathname.includes('resumen')) {
        this.steps = 'stepsResumen'
        this.btnCartButtonView = 'btnCartButtonViewResumen'
      } else if (this.pathname.includes('finish')) {
        this.steps = 'stepsFinish'
      }

      this.bannerView = 'bannerView2'
      this.cartBoxView = 'cartBoxView2'
      this.title1View = 'title1View2'
      this.title2View = 'title2View2'
      this.btnFindSoapView = 'btnFindSoapView2'
      this.stepperHeaderView = 'stepperHeaderView2'
      this.stepLabelSelected = 'stepLabelSelected2'
      this.btnFindSoapView3 = 'btnFindSoapView3Mobile'
      this.btnFindSoapTitleBox = 'btnFindSoapTitleBox2'
      this.btnBackMobile = 'btnBackMobile'

    } else if (this.screen >= 768 && this.screen <= 1000) {
      this.steps = 'steps'
      this.bannerView = 'bannerView'
      this.cartBoxView = 'cartBoxViewTablet'
      this.btnFindSoapView = 'btnFindSoapView'
      this.stepperHeaderView = 'stepperHeaderView'
      this.stepLabelSelected = 'stepLabelSelected'
      this.btnFindSoapView3 = 'btnFindSoapView3'
      this.title1View = 'title1View'
      this.title2View = 'title2View'
      this.btnFindSoapTitleBox = 'btnFindSoapTitleBoxTablet'

      if (this.pathname.includes('datos')) {
        this.btnCartButtonView = 'btnCartButtonViewSummaryDatos'
      } else if (this.pathname.includes('resumen')) {
        this.btnCartButtonView = 'btnCartButtonViewSummaryResumen'
      }

      this.btnBack = 'btnBack'
    }

    if (query) {
      if (query.success) {
        // this.querySuccessState = 'ok'
        this.servicesData.e1 = 3

        return;
      }

      if ((this.servicesData.e1 == 2 && this.arrayShoppingCart.length == 0) || this.servicesData.loadInitial == false) {

        localStorage.removeItem('carData');
        localStorage.removeItem('planData');
        localStorage.removeItem('propietarioData');
        localStorage.removeItem('servicesData');
        localStorage.removeItem('arrayShoppingCart');

        swal({
          title: "Lo sentimos!",
          text: "No existen registros correspondientes a la patente ingresada",
          icon: "info",
          button: "Ok",
        }).then(() => {
          window.location.href = `/${this.servicesData.typeCliente}/inicio`
          // this.$router.push({ path: `/${this.servicesData.typeCliente}/inicio` });
        });

      } else if (this.carData.patente && this.servicesData.e1 == 1) {
        // if (this.carData.patente && this.propietarioData.email && this.servicesData.e1 == 1) {

        // reCaptcha v3 token
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha('consultSoap');

        let soapRes = await SoapService.consultaSoap(this.carData.patente, token);

        if (soapRes.data == null) {

          this.carData.id = '';
          this.carData.year = '';
          this.carData.patenteDv = '-';
          this.carData.type = null;
          this.carData.marca = '';
          this.carData.model = '';
          this.carData.motor = '';
          this.propietarioData.rut = '';
          var nombreCompleto = '';
          this.propietarioData.nombres = '';
          this.propietarioData.firtlastname = '';
          this.propietarioData.secondlastname = '' ;
          this.loadSoap = false;
          this.carData.newFlujo = true;

          } else if (soapRes.status == 'ok') {

          this.carData.id = soapRes.data._id;
          this.carData.year = soapRes.data.anio_fabricacion;
          this.carData.patenteDv = soapRes.data.dv_ppu;
          this.carData.type = soapRes.data.tipo_vehiculo;
          this.carData.marca = soapRes.data.marca;
          this.carData.model = soapRes.data.modelo;
          this.carData.motor = soapRes.data.numero_motor;

          this.propietarioData.rut = soapRes.data.rut + soapRes.data.dv;

          let nombreCompleto = soapRes.data.nombre_razonsocial.split(' ');

          this.propietarioData.nombres = nombreCompleto[3] ? nombreCompleto[2] + ' ' + nombreCompleto[3] : nombreCompleto[2];
          this.propietarioData.firtlastname = nombreCompleto[0];
          this.propietarioData.secondlastname = nombreCompleto[1];
          this.loadSoap = false;

          this.carData.newFlujo = false;

          switch (this.carData.type) {
          case 'AUTOMOVIL':
          case 'JEEP':
          case 'STATION WAGON':
          case 'LIMUSINA':
            this.carData.price = this.planData.soap.car.price;
            this.carData.pricePuntos = this.planData.soap.car.pricePuntos;
            this.propietarioData.remarque = this.planData.soap.car.remarque;
            this.carData.externalProductId = this.planData.soap.car.rowID;
            break;

          case 'CAMIONETA':
          case 'FURGON':
            this.carData.price = this.planData.soap.camioneta.price;
            this.carData.pricePuntos = this.planData.soap.camioneta.pricePuntos;
            this.propietarioData.remarque = this.planData.soap.camioneta.remarque;
            this.carData.externalProductId = this.planData.soap.camioneta.rowID;
            break;

          case 'MOTO':
          case 'CUATRIMOTO':
          case 'MOTONETA':
          case 'TRIMOTO':
            this.carData.price = this.planData.soap.moto.price;
            this.carData.pricePuntos = this.planData.soap.moto.pricePuntos;
            this.propietarioData.remarque = this.planData.soap.moto.remarque;
            this.carData.externalProductId = this.planData.soap.moto.rowID;
            break;

          case 'CARRO DE ARRASTRE':
          case 'CASA RODANTE MOTORIZADA':
          case 'REMOLQUE':
            this.carData.price = this.planData.soap.arrastre.price;
            this.carData.pricePuntos = this.planData.soap.arrastre.pricePuntos;
            this.propietarioData.remarque = this.planData.soap.arrastre.remarque;
            this.carData.externalProductId = this.planData.soap.arrastre.rowID;
            break;

          case 'MINIBUS':
          case 'MICROBUS':
            this.carData.price = this.planData.soap.bus.price;
            this.carData.pricePuntos = this.planData.soap.bus.pricePuntos;
            this.propietarioData.remarque = this.planData.soap.bus.remarque;
            this.carData.externalProductId = this.planData.soap.bus.rowID;
            break;

            default:
            swal({
              title: "¡Tipo de vehículo no válido!",
              icon: "info",
              button: "Ok",
            }).then(() => {
              window.location.href = `/${this.servicesData.typeCliente}/inicio`
              // this.$router.push({ path: `/${this.servicesData.typeCliente}/inicio` });
            });
            break;
          }
        }

        try {

          // reCaptcha v3 token
          await this.$recaptchaLoaded();
          const tokenConsulta = await this.$recaptcha('consultaSoap');

          await ShieldService.createSoapConsulta(this.propietarioData, this.carData, tokenConsulta);

        } catch (error) {
          console.log(error);
        }

      }
    }

    this.calculateFinalPrice();

  },
  methods: {
    async logincenco() {

      const currentDate = new Date();
      const currentHour = ('0' + currentDate.getHours()).slice(-2);
      const currentMinutes = ('0' + currentDate.getMinutes()).slice(-2);
      const currentDay = ('0' + currentDate.getDate()).slice(-2);
      const currentMonth = ('0' + (currentDate.getMonth() + 1)).slice(-2);
      const time = currentHour + ':' + currentMinutes;
      this.servicesData.keygen = keygen.url(keygen.large) + `-${currentDay}/${currentMonth}-${time}`;
      // console.log(this.arrayShoppingCart);
      this.clienteCenco = []
      this.CencoSelect = false
      this.webPaySelect = true
      if (this.$refs.form.validate()) {
        this.loadLogin = true
        this.tokenpartner = await BodumService.getTokenPartner();
        // console.log("token partnet: ", this.tokenpartner);
        this.dialog = true;
        this.respuestaLogin = await BodumService.loginsocio(this.tokenpartner, this.rutCliente, this.contrasenaCliente);
        // console.log("token login: ", this.respuestaLogin);
        if (this.respuestaLogin.code == 'USER_TMP_PWD' || this.respuestaLogin.code == 'OK') {
          this.paymentMethodDisabled = true;
          this.openDialog = true;
          this.loadPuntos = true
          this.tokensocio = this.respuestaLogin.token;
          this.puntosclientes = await BodumService.consultaPuntos(this.tokenpartner, this.rutCliente);
          // console.log(this.puntosclientes);
          this.loadPuntos = false;
          if (this.switchFormaPago == false && this.tokensocio != null) {
            const datos = {
              name: this.puntosclientes.nombre,
              surname: this.puntosclientes.apellidoPaterno,
              points: this.puntosclientes.puntos,
              pointsCenco: this.arrayShoppingCart[0].carData.pricePuntos,
            }

            this.clienteCenco.push(datos);

            let res = await ShieldService.pagoCencosud(this.arrayShoppingCart, this.servicesData, this.planData, this.servicesData.keygen);

            if (res.includes('success=true')) {
              this.generaropt = await BodumService.GenerarOPT(this.tokenpartner, this.tokensocio);
              if (this.generaropt.status == "ok") {
                this.dialogOpt = true
              }
            };

            this.loadLogin = false;

            swal({
              title: "Login Exitoso",
              text: "Ahora puede continuar con la compra!",
              icon: "success",
              button: "Ok",
            });

            this.status = true
            this.dialog = false;

            if (datos.points < datos.pointsCenco) {
              this.paymentMethodDisabled = false;
              swal({
                title: "Ups! Faltan puntos para contratar el seguro.",
                text: "Porfavor, cambiar método de pago!",
                icon: "error",
                button: "Ok",
              });
            }

          } else {
            this.loadLogin = false
            swal({
              title: "Credenciales inválidas",
              text: "",
              icon: "error",
              button: "Ok",
            });
          }

        } else {
          swal({
            title: "Error en la autenticación",
            text: "Inténtelo nuevamente",
            icon: "error",
            button: "Ok",
          }).then(() => {
            location.reload();
          });
        }
      }
    },
    async canjearpuntos() {
      this.loadCanje = true;
      this.loading = true;

      this.externalId = this.arrayShoppingCart[0].carData.patente + '2025' + '-01';
      // console.log(this.externalId);
      let validarcanje = await BodumService.PagoPuntos(this.tokenpartner, this.tokensocio, this.codigoOpt, this.arrayShoppingCart[0].carData.pricePuntos, this.arrayShoppingCart[0].carData.externalProductId, this.servicesData.keygen, this.externalId);
      // console.log(validarcanje);
      if (this.$refs.form.validate()) {
        if (validarcanje.status == 'ok') {

          const query = this.$route.query;
          this.loadCanje = false
          let res = await ShieldService.processSoap(this.arrayShoppingCart, this.servicesData, this.planData, this.servicesData.keygen);

          // console.log("respuesta de shield: ", res);

          if (res.status == 'ok') {
            localStorage.removeItem('carData');
            localStorage.removeItem('planData');
            localStorage.removeItem('propietarioData');
            localStorage.removeItem('servicesData');
            localStorage.removeItem('arrayShoppingCart');
            this.loading = false;
            this.status = false;
            window.location.href = res.data;
            this.loadPuntos = true;
            this.status = false;
            swal({
              title: "Canje Exitoso",
              text: "La compra del seguro se ha completado",
              icon: "success",
              buttons: false, 
              closeOnClickOutside: false, 
              closeOnEsc: false 
            });
            this.loadPuntos = true;
            this.status = false;
          } else {
            this.loading = false
            this.status = false
            this.loadTokenPayment = false;
            this.loadPuntos = true;
            swal({
              title: "Error al procesar el SOAP",
              text: "",
              icon: "error",
              button: "Ok",
            });
          }
        } else {
          this.paymentMethodDisabled = false;
          this.loading = false
          this.openDialog = false
          this.loadCanje = false
          swal({
            title: "Canje inválido",
            text: "",
            icon: "error",
            button: "Ok",
          });
        }
      }
    },
    handlePaymentMethodChange() {
      if (this.selectedPaymentMethod === 'tarjeta') {
        this.selectedPaymentMethod = 'tarjeta';
      } else if (this.selectedPaymentMethod === 'puntos') {
        this.selectedPaymentMethod = 'puntos';
        this.switchFormaPago = false
      }
    },
    // async redirectFindSoap() {
    //   const consulta_1 = document.getElementById('consulta_1');
    //   const consulta_5 = document.getElementById('consulta_5');
    //   window.location.href = process.env.VUE_APP_DOMINIO + 'consulta'
    //   // this.$router.push({ path: "/consulta" });
    // },
    async obtenerTokenCenco() {

      event.preventDefault();

      if (this.servicesData.rutPropietario == null || this.servicesData.rutPropietario == 'null' || this.servicesData.rutPropietario == undefined || this.servicesData.rutPropietario == '' || this.servicesData.rutPropietario.length <= 10 || this.servicesData.rutPropietario.length > 12) {
        swal({
          icon: 'info',
          title: '¡Ups!',
          text: 'Debes ingresar el rut pagador',
          timer: 3000,
          buttons: false,
        });
      } else {
        this.planId = localStorage.getItem('planId');
        this.loadTokenPayment = true;

        //  if (this.selectedPaymentMethod === 'tarjeta' || this.selectedPaymentMethod != 'puntos') {

        // console.log("dkjdkjkjd");

        // var webPayToken = await ShieldService.create(this.keygen, this.planId);
        // if (webPayToken.status == 'ok') {
        //   this.token = webPayToken.data.token;
        //   setTimeout(() => {
        //     this.submitPago();
        //   }, 3000);
        // } else {
        //   this.loadTokenPayment = false;
        //   swal({
        //     title: "UPS! Algo salió mal.",
        //     text: "Vuelva a intentarlo nuevamente.",
        //     icon: "info",
        //     button: "Ok",
        //   }).then(() => {
        //     window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '/inicio'
        //   });
        // }
        // } else if (this.selectedPaymentMethod === 'puntos') {

        // console.log(this.selectedPaymentMethod );        
        this.selectedPaymentMethod === 'puntos'
        if (this.openDialog == false) {
          this.dialog = 'true';
          this.loadTokenPayment = false;
        }
      }
    },

    redirectBack() {
      // console.log('typeCliente: ',this.servicesData.typeCliente) 
      switch (this.pathname) {
        case `/${this.servicesData.typeCliente}/datos`:
          window.location.href = `/${this.servicesData.typeCliente}/inicio`;
          // this.$router.push({ path: `/${this.servicesData.typeCliente}/inicio` });
          break;

        case `/${this.servicesData.typeCliente}/resumen`:
          window.location.href = `/${this.servicesData.typeCliente}/datos`;
          localStorage.setItem('backButton', 'SummaryToData');
          // this.$router.push({ path: `/${this.servicesData.typeCliente}/datos` });
          break;

        default:
          break;
      }
    },
    calculateFinalPrice() {
      if (this.arrayShoppingCart) {
        this.servicesData.finalPrice = this.arrayShoppingCart.reduce((total, item) => {
          return total + (item.carData?.price || 0);
        }, 0);
      } else {
        this.servicesData.finalPrice = 0;
      }
    },
    async obtenerToken() {

      event.preventDefault();
      if (this.servicesData.rutPropietario == null || this.servicesData.rutPropietario == 'null' || this.servicesData.rutPropietario == undefined || this.servicesData.rutPropietario == '' || this.servicesData.rutPropietario.length <= 10 || this.servicesData.rutPropietario.length > 12) {
        swal({
          icon: 'info',
          title: '¡Ups!',
          text: 'Debes ingresar el rut pagador',
          timer: 3000,
          buttons: false,
        });
        return;
      } else if (!this.servicesData.optionDonate && this.servicesData.priceDonation == 0) {
        swal({
          icon: 'info',
          title: '¡Ups!',
          text: 'Debes seleccionar si deseas realizar o no una donación!',
          timer: 3000,
          buttons: false,
        });
        return;
      }
      // reCaptcha v3 token
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha('createSoap');

      this.loadTokenPayment = true;

      const currentDate = new Date();
      const currentHour = ('0' + currentDate.getHours()).slice(-2);
      const currentMinutes = ('0' + currentDate.getMinutes()).slice(-2);
      const currentDay = ('0' + currentDate.getDate()).slice(-2);
      const currentMonth = ('0' + (currentDate.getMonth() + 1)).slice(-2);

      const time = currentHour + ':' + currentMinutes;

      this.servicesData.keygen = keygen.url(keygen.large) + `-${currentDay}/${currentMonth}-${time}`;

      const servicesDataLocal = JSON.stringify(this.servicesData);
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
      localStorage.setItem('servicesData', encryptedServicesDataLocal);

      let webPayToken = await ShieldService.create(this.arrayShoppingCart, this.servicesData, this.planData, token);
      if (webPayToken.status == 'ok') {
        this.token = webPayToken.data.token;
        setTimeout(() => {
          this.submitPago()
        }, 3000);
      } else {
        swal({
          title: "UPS! Algo salió mal.",
          text: "Vuelva a intentarlo nuevamente.",
          icon: "info",
          button: "Ok",
        }).then(() => {
          localStorage.removeItem('carData');
          localStorage.removeItem('planData');
          localStorage.removeItem('propietarioData');
          localStorage.removeItem('servicesData');
          localStorage.removeItem('arrayShoppingCart');
          window.location.href = `/${this.servicesData.typeCliente}/inicio`
          // this.$router.push({ path: `/${this.servicesData.typeCliente}/inicio` });
        });
      }
    },
    async submitPago() {
      if (this.token && this.conditions && this.servicesData.rutPropietario) {
        this.$refs.form_payment.submit();
        return;
      } else {
        swal({
          icon: 'info',
          title: '¡Precaución!',
          text: 'Debes autorizar el tratamiento de tus datos personales e ingresar rut de quién realizará el pago.',
          timer: 2000,
          buttons: false,
        });
      }
    },
    async redirectFindSoap() {
      const consulta_1 = document.getElementById('consulta_1');
      const consulta_5 = document.getElementById('consulta_5');
      window.location.href = `/consulta`
      // this.$router.push({ path: `/consulta` });
    },
    btnVolver() {
      localStorage.removeItem('carData');
      localStorage.removeItem('propietarioData');
      window.location.href = `/${this.servicesData.typeCliente}/inicio`
      // this.$router.push({ path: `/${this.servicesData.typeCliente}/inicio` });
    },
    getToken(elementos) {
      // this.token = elementos.token;
      // this.propietarioData.rutPropietario = elementos.rut;
      this.conditions = elementos.conditions;
    },
    async changeRut2(rut) {
      this.servicesData.rutCliente = RutUtils.formatRut(rut);
      this.rutCliente = RutUtils.formatRut(rut);
    },
    goToLanding() {
      window.location.href = "https://www.seguroscencosud.cl/seguro-automotriz/landing-auto";
    },
  },
};
</script>

<style lang="scss">
.v-stepper__header {
  box-shadow: none !important;
}

.v-main__wrap {
  flex: 1 1 auto;
  max-width: 100%;
  position: relative;
  margin-bottom: -7%;
}

.btnBack {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  text-transform: none !important;
  color: rgba(51, 51, 51, 1) !important;
  font-weight: bold !important;
  letter-spacing: 0.4px;
  font-size: 20px !important;
  position: absolute;
  top: -5%;
  z-index: 999999;
  left: 2%;
}

.btnBackMobile {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  text-transform: none !important;
  color: white !important;
  font-weight: bold !important;
  letter-spacing: 0.4px;
  // font-size: 20px !important;
  position: absolute;
  top: -3.9%;
  left: 1%;
}

.btnBackMobileSummary {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  text-transform: none !important;
  color: white !important;
  font-weight: bold !important;
  letter-spacing: 0.4px;
  // font-size: 20px !important;
  position: absolute;
  top: -10.2%;
  left: 1%;
}

.btnFindSoapTitleBox {
  width: 70%;
  margin-right: -5%;
}

.btnFindSoapTitleBoxTablet {
  width: 70%;
  margin: auto;
  margin-bottom: 1%;
}

.btnFindSoapTitleBox2 {
  width: 100%;
  margin: auto;
  margin-bottom: 3%;
  text-align: center;
}

.theme--light.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
  background: rgba(142, 153, 187, 1);
  border-radius: 50%;
  overflow: hidden;
  font-size: 18px;
  font-weight: bold;
  // /* Evitar que el contenido se visualice fuera del borde*/
}

.theme--light.v-stepper .v-stepper__label {
  text-align: center;
  font: Arial;
  font-size: 15px;
  letter-spacing: 0.23px;
  color: rgba(92, 102, 112, 1);
  opacity: 1;
  font-size: 15px;
  width: 208px;
}

.v-input .v-label {
  text-align: left;
  font-size: 12px;
  font: Arial;
  letter-spacing: 0.18px;
  color: #2D387C;
  opacity: 1;
}

span.v-stepper__step__step.primary {
  background: rgba(0, 105, 185, 1) !important;
  font-size: 18px;
  font-weight: bold;
}

.steps {
  width: 100%;
}

.stepsDatosSin {
  margin-top: -20%;
}

.stepsDatosCon {
  margin-top: 5%;
}


.stepsFinish {
  margin-top: -25%;
}

.btnCartButtonViewDatos {
  z-index: 9999 !important;
  margin-top: -5%;
  margin-left: 35%;
}

.btnCartButtonViewResumen {
  z-index: 9999 !important;
  margin-top: -5%;
  margin-left: 35%;
}

.containerStyle {
  background-color: #FFF;
  height: 80%;
  margin-bottom: 6.5%;
}

.containerStyle2 {
  background-color: #FFF;
  height: 80%;
  margin-bottom: 6%;
}

.stepLabelSelected {
  font-size: 16px;
  font-weight: normal;
}

.stepLabelSelected2 {
  font-size: 12px;
  font-weight: normal;
  width: 80%;
}

.stepLabel {
  font-size: 20px;
}

.btnFindSoapView {
  width: 270px;
  height: 40px !important;
  background: rgba(0, 105, 185, 1) 0% 0% no-repeat padding-box !important;
  border-radius: 6px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  cursor: pointer;
  margin-top: -0.5%;
}

.btnFindSoapView:hover {
  background: #ED0722 !important;
  color: #FFFFFF !important;
}

.btnFindSoapView2 {
  width: 200px;
  height: 36px !important;
  background: rgba(0, 105, 185, 1) 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  text-align: center;
  font: normal normal normal 22px/27px Arial;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  // margin-left: -105%;
  margin: auto;
}

.btnFindSoapView3 {
  width: 180px;
  height: 36px !important;
  background: #1D9C6F 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  text-align: center;
  font: normal normal normal 22px/27px Arial;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  margin-left: 70%;
  margin-top: 5%;
  // margin-bottom: -10.99%;
}

.btnFindSoapView3Mobile {
  width: 200px;
  height: 36px !important;
  background: #1D9C6F 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  // margin-left: -107%;
  // margin-top: 20%;
  font-size: 14px !important;
  // margin-bottom: -22%;
  margin-left: -1%;
}

.cart {
  margin-left: 58%;
  margin-bottom: 1%;
}

.cartBoxView {
  position: relative;
  display: flex;
  height: 60px;
  padding: 1%;
  width: 70%;
  gap: 3%;
  margin: auto;
  border-radius: 6px;
  box-shadow: 0 0 0 1px rgba(0, 105, 185, 1), 0 0 0 2px rgba(0, 105, 185, 1);
}

.cartBoxViewTablet {
  position: relative;
  display: block;
  height: 88px;
  padding: 1%;
  width: 95%;
  gap: 10%;
  margin: auto;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #2d5570, 0 0 0 2px #2d5570;
  height: 134px;
  // margin-bottom: 8%;
}

.cartBoxView2 {
  margin: auto;
  border-radius: 6px;
  box-shadow: 0 0 0 1px rgba(45, 85, 112, 1), 0 0 0 2px rgba(45, 85, 112, 1);
  width: 90%;
  max-height: 160px;
}

.finalPayContainer {
  background-color: #F3F3F3;
  width: 116.5%;
  margin-left: -8%;
  margin-right: auto;
  margin-bottom: -9%;
}

.btnCartButtonView {
  position: absolute;
  right: 16%;
  top: -6%;
  z-index: 9999 !important;
}

.btnCartButtonViewSummaryDatos {
  z-index: 9999 !important;
  margin-left: 40%;
}

.btnCartButtonViewSummaryResumen {
  z-index: 9999 !important;
  margin-left: 40%;
  margin-top: -2%;
  padding-bottom: 2%;
}

.finalPayContainerMovil {
  width: 116.5%;
  margin-left: -8%;
  margin-right: auto;
  margin-bottom: -11%;
}

.finalPayContainerDesktop {
  background-color: #FFF;
  width: 140%;
  margin-left: -19%;
  margin-bottom: -12.6%;
}

@media (min-width: 767px) and (max-width: 1000px) {
  .v-main__wrap {
    flex: 1 1 auto;
    max-width: 100%;
    position: relative;
    margin-bottom: -7%;
  }
}

.box {
  display: flex;
  margin-bottom: -25%;
}

.boxMovile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -38%;
}

.left {
  padding: 10px;
  width: 40%;
  display: flex;
  justify-content: center;

}

.right {
  padding: 10px;
  width: 90%;
  // background-color: yellowgreen;
}

.images {
  margin-top: 6%;
  margin-bottom: 6%;
}

.imagesMobile {
  margin-top: 6%;
  margin-bottom: 6%;
  width: 95%;
  margin-left: 3%;
}

.payBoxtitle {
  width: 750px;
  height: 29px;
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  font-size: 30px;
  letter-spacing: -0.36px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
}

.payBoxtitleMovileBox {
  margin-left: 2%;
}

.payBoxtitleMovile {
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
  font-size: 20px;
}

.imagesContainerMovile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin-left: 16.5%;
}

.imagesContainerMovile>div {
  min-width: 70%;
  background-color: #EB4949;
  flex-grow: 1;
  padding: 10px;
  text-align: center;
  margin-right: 5%;
  height: 80px;
  display: flex;
  align-items: center;
}

.btnKnowMoreMovile {
  width: 250px;
  height: 50px !important;
  flex-shrink: 0;
  color: white !important;
  font-weight: bold;
  font-size: 16px !important;
  letter-spacing: 0.8px;

}

.btnKnowMore {
  background-color: #4F74E3 !important;
  color: #FFF !important;
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 0.8px;
  height: 20% !important;
  width: 22%;
  font-size: 16px !important;
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.8px;
}

.imagesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagesContainer>div {
  background-color: #EB4949;
  flex-grow: 1;
  padding: 10px;
  text-align: center;
  margin-right: 5%;
  height: 80px;
  display: flex;
  align-items: center;
}

.stepperHeaderView {
  box-shadow: none;
  margin: auto;
  width: 75%;
  margin-bottom: -2%;
}

.stepperHeaderView2 {
  box-shadow: none;
  margin: auto;
  width: 100%;
  margin-bottom: -8%;
}

.opcionPago {
  color: rgba(0, 105, 185, 1);
  font-family: 'Arial', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.24px;
  text-transform: uppercase;
  // margin: auto;
}

.button1 {
  width: 270px;
  height: 50px !important;
  background: none !important;
  border-radius: 10px !important;
  border: 1px solid rgba(0, 105, 185, 1);
  opacity: 1;
  text-align: center;
  font-size: 18px !important;
  letter-spacing: 0.66px;
  color: rgba(0, 105, 185, 1) !important;
  opacity: 1;
  margin-left: -7%;
  font-weight: bold;
}

.button1Mobile {
  width: 270px;
  height: 50px !important;
  background: none;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font-size: 18px !important;
  letter-spacing: 0.66px;
  color: rgba(0, 105, 185, 1) !important;
  opacity: 1;
  margin-left: 5%;
  font-weight: bold;
  margin-bottom: 2%;
  border: 1px solid rgba(0, 105, 185, 1);
}
.button1MobileCenco {
  width: 270px;
  height: 50px !important;
  background: none;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font-size: 18px !important;
  letter-spacing: 0.66px;
  color: rgba(0, 105, 185, 1) !important;
  opacity: 1;
  margin-bottom: 5%;
  font-weight: bold;
  border: 1px solid rgba(0, 105, 185, 1);
}

.button2 {
  width: 270px;
  height: 50px !important;
  background-color: rgba(255, 125, 0, 1) !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font-size: 18px !important;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  margin-left: 35%;
  font-weight: bold;
}

.button2Mobile {
  width: 270px;
  height: 50px !important;
  background-color: rgba(255, 125, 0, 1) !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font-size: 18px !important;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  margin-left: -2%;
  font-weight: bold;
}

.button2MobileCenco {
  width: 270px;
  height: 50px !important;
  background-color: rgba(255, 125, 0, 1) !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font-size: 18px !important;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
}

.title1View {
  font-family: 'Arial', sans-serif;
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  font-size: 20px;
}

.title1View2 {
  font-family: 'Arial', sans-serif;
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  font-size: 20px;
}

.title2View {
  margin-left: 1%;
  font-family: 'Arial', sans-serif;
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  font-size: 20px;
}

.title2View2 {
  font-family: 'Arial', sans-serif;
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  font-size: 20px;
  display: block;

}

.v-stepper:not(.v-stepper--vertical) .v-stepper__label {
  display: contents !important;
}

@media (min-width: 1440px) {
  .v-main__wrap {
    flex: 1 1 auto;
    max-width: 100%;
    position: relative;
    margin-bottom: -6%;
  }
}

.v-btn:before {
  background-color: transparent !important;
}

.imageCenco {
  background-image: url("~@/assets/images/CencoImage.png");
  background-position: center;
  background-size: cover;
  display: flex;
}

.login {
  display: inline-block;
  box-sizing: border-box;
  border-radius: 0.3rem;
  background-color: rgb(19, 65, 105) !important;
  height: 40px !important;
  line-height: 40px;
  width: 100%;
  padding: 0px 1.5rem !important;
  cursor: pointer;
  color: white !important;
  font-weight: 600;
  font-family: 'Nunito Sans', sans-serif;
  margin: auto;
}

.condicion {
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
  color: #1269B0;
  text-transform: uppercase;
  opacity: 1;
}
</style>