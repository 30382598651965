<template>
  <v-container
    style="position: relative; width: 100%; justify-content: center; height: 100%; background: #FFFFFF 0% 0% no-repeat padding-box; opacity: 1;">

    <div class="text-center">

      <br>

      <div v-if="pagar == false" class="rectangulo">

        <div class="valor">
          <b> Valor total a pagar</b>
        </div>

        <br>

        <div  v-if="$route.path.includes('/puntos')" :class="priceBox" class="text-center">
      
          <div style="display: flex; gap: 10px" :class="priceContainer">
            <hr :class="hrStyle">
            <b class="precio">{{ carData.pricePuntos }}</b>
            <v-img contain :src="puntosPrice" max-width="35" class="" />
            <hr :class="hrStyle2">
          </div>
        </div>

        <div v-else :class="priceBox" class="text-center">
          <b class="precio">{{ formatPrice(servicesData.priceTotal) }}</b>
        </div>

        <br>

      </div>

      <br>
      <br>

      <div v-if="pagar != false" :class="summaryTitle">
        <b>Este es el resumen del SOAP que estás contratando</b>
        <br>
        <br>
        <br>
      </div>

      <template>

        <v-data-table :footer-props="{ 'items-per-page-text': '' }" :headers="computedHeaders"  :items="arrayShoppingCart"
          :items-per-page="isMobile ? 1 : 5" class="elevation-1 table" no-data-text="CARRITO VACÍO ¡AÑADE TU SOAP!">
          <template v-slot:top>
            <v-dialog v-if="screen > 767" v-model="dialogDelete" max-width="600px">
              <v-card>

                <v-card-title class="text-h5">
                  <div class="titleBox">
                    <b class="title1">¿Estás seguro de </b>
                    <b class="title2">eliminar</b>
                    <b class="title1">este producto?</b>
                  </div>
                  <div :class="dialogCarBox">
                    <b>Vehículo: {{ selectedItem ? selectedItem.carData.patente : '' }}</b><b
                      style="font-weight: normal;">{{ selectedItem ? selectedItem.carData.model : '' }}</b>
                  </div>
                </v-card-title>

                <v-card-actions>

                  <v-spacer></v-spacer>
                  <v-btn class="btnClosePopUp" text @click="closeDelete">Cancelar</v-btn>
                  <v-btn class="btnConfirmPopUp" id="quitarOtro_3" text @click="deleteItemConfirm">Confirmar</v-btn>
                  <div class="space"></div>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-else v-model="dialogDelete" max-width="360px">
              <v-card>

                <v-card-title class="text-h5">
                  <div class="titleBoxMobile">
                    <b class="title1Mobile">¿Estás seguro de eliminar<br>este producto?</b>
                  </div>
                </v-card-title>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <div class="btnBoxMobile">
                    <v-btn class="btnClosePopUp2" text @click="closeDelete">Cancelar</v-btn>
                    <br><br>
                    <v-btn class="btnConfirmPopUp2" id="quitarOtro_3" text @click="deleteItemConfirm">Confirmar</v-btn>
                    <div class="space"></div>
                  </div>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

          </template>

          <template v-slot:item.actions="{ item }">
            <div style="cursor: pointer;" @click="deleteItem(item)">
              <v-icon small class="mr-2 trash">
                mdi-delete
              </v-icon>
              <span style="color:red;">Eliminar</span>
            </div>
          </template>

          <template v-slot:item.carData.price="{ item }">
            <label style="margin:auto">{{ formatPrice(item.carData.price) }}</label>
          </template>


        </v-data-table>

      </template>

      <br>
      <br>

      <div v-if="pagar != true" class="finalBox">

        <b v-if="!this.pathname.includes('puntos')" class="question">¿QUIERES AGREGAR OTRO VEHÍCULO?</b>
        <br v-if="screen <= 767">
        <br v-if="screen <= 767">
        <v-btn v-if="!this.pathname.includes('puntos')" class="button3" id="agregarOtro_3" @click="NewCarro()">Agregar otro </v-btn>
        <br v-if="screen <= 767">
        <br v-if="screen <= 767">
        <v-btn v-if="screen > 767" class="button2" @click="obtenerToken()" id="confirmar_3">Continuar pago</v-btn>

        <v-btn v-if="screen <= 767" class="button2" style="margin-top: 5%" @click="obtenerToken()" id="confirmar_3">
          Continuar Pago
        </v-btn>

      </div>

      <v-form ref="form" v-model="valid">

        <v-col v-if="pagar != false" style="margin:auto;">
          <div v-if="!this.pathname.includes('puntos')" :class="donateBox">
              <div v-if="screen <= 800" class="spaceMobile"></div>
              <div :class="donateMessageBox">
                <b :class="donateMessageFirst">!Ya casi está listo!</b>
                <b :class="donateMessageSecond">Antes de terminar,</b>
              </div>
              <div :class="donateMessageBoxBlack">
                <b :class="donateMessageSecondBlack">¿Deseas donar un monto a Fundación Las Rosas?</b>
                <!-- <b :class="donateMessageFirst" style="margin-left: 0.6%;">donar un aporte</b> -->
              </div>
              <div v-if="screen <= 800" class="spaceMobile"></div>
              <div :class="donateOptionsBox">
                <label v-for="option in donateOptions" :key="option.value" :class="radioOption">
                  <div :class="donationContainer">
                    <v-img v-if="screen > 800" contain :src="option.image" max-width="200" class="images" />
                    <v-img v-else contain :src="option.image" max-width="100" class="images" />
                    <span :class="donateDescription">{{ option.label }}</span>
                  </div>
                  <br>
                  <!-- <input type="radio" :value="option.name" v-model="servicesData.entityDonation" /> -->
                </label>
              </div>

              
              <br>
              <div :class="buttonOptionsBox">
                <div v-if="screen <= 800" :class="buttonGroup">
                  <button v-for="donation in donationData[1]" :key="donation.secret" type="button"
                    :class="[donateButton, { selected: servicesData.priceDonation === donation.amount }]"
                    @click.prevent="selectDonation(donation)">
                    {{ formatter.format(donation.amount) }}
                  </button>
                </div>
                <div v-else :class="buttonsDonateBox">
                  <div style="display: flex;gap: 16%;margin: auto;width: 100%;">
                    <button v-for="donation in donationData[0][0]" :key="donation.secret" type="button"
                      :class="[donateButton, { selected: servicesData.priceDonation === donation.amount }]"
                      @click.prevent="selectDonation(donation)">
                      {{ formatter.format(donation.amount) }}
                    </button>
                  </div>
                  <br><br>
                  <!-- <div style="display: flex;gap: 16%;margin: auto;width: 100%;max-width:600px;">
                    <button v-for="donation in donationData[0][1]" :key="donation.secret" type="button"
                      :class="[donateButton, { selected: servicesData.priceDonation === donation.amount }]"
                      @click.prevent="setAmount(donation)">
                      {{ formatter.format(donation.amount) }}
                    </button>
                  </div> -->
                </div>
              </div>
              <div :class="donateOptionsBox">
                <label :class="noDonarOption">
                  <input type="radio" :value="true" v-model="servicesData.optionDonate" @change="clearSelectedButtons" />
                  <span style="font-size: 16px;font-weight: 600;">Prefiero no donar en este momento.</span>
                </label>
              </div>
            </div>
          <br>

          <div :class="summaryBox">

            <br>

            <b :class="summaryTitle">Resumen de tu contratación</b>

            <div :class="summaryBanner">

              <div v-if="screen > 767">
                <v-img contain :src="SummaryBanner" :class="bannerImage" />

              </div>

              <div v-else>
                <br>
                <v-img contain :src="SummaryBannerMobile" :class="bannerImage"/>
              </div>

              <b :class="summaryBannerTitle">Seguro obligatorio de accidentes personales</b>

              <br>
              <br>

            </div>

            <br>

            <div :class="summaryDataBox">

              <br>
              <b :class="summaryDataTitle">Detalles del pago</b>

              <hr style="border:none;height: 2px;background-color: rgba(231, 233, 235, 1);width: 90%; margin: auto;">

              <div v-if="!this.pathname.includes('puntos')">
                <div v-for="(item, index) in arrayShoppingCart" :key="index" :class="summaryPrices">
                  <span>{{ item.carData.model }}</span>
                  <span>{{ formatter.format(item.carData.price) }}</span>
                </div>
                <div v-if="servicesData.priceDonation != null" :class="summaryPrices">
                  <span>Donación {{ servicesData.entityDonation }}</span>
                  <span>{{ formatter.format(servicesData.priceDonation) }}</span>
                </div>
                <hr style="border:none;height: 2px;background-color: rgba(231, 233, 235, 1);width: 90%; margin: auto;">

                <div :class="summaryPricesTotal">
                  <span>Total:</span>
                  <span>{{ formatter.format(servicesData.finalPrice) }}</span>
                </div>
             </div> 
             <div v-else >
                <div v-for="(item, index) in arrayShoppingCart" :key="index" :class="summaryPrices">
                  <span>{{ item.carData.model }}</span>
                  <span>{{ carData.pricePuntos }}</span>
                </div>
                <hr style="border:none;height: 2px;background-color: rgba(231, 233, 235, 1);width: 90%; margin: auto;">

                <div :class="summaryPricesTotal">
                  <span>Total:</span>
                  <span>{{ carData.pricePuntos }} Puntos</span>
                </div>
              </div>

              <br>

            </div>

            <br>
            <br>

          </div>

          <br>
          <br>
          <br>

          <b class="title"> Ingresa el RUT pagador</b>

          <br>
          <br>

          <div>
            <label :class="patentLabelView"><b>Ingrese el RUT</b></label>
            <v-text-field v-model="newRut" :rules="rutRules" required solo :class="patentFieldView" hide-details="auto"
              @change="changeRut">
            </v-text-field>
          </div>

        </v-col>

        <br>

        <v-col v-if="pagar != false">

          <div class="checkbox">

            <v-checkbox style="margin-top:2.5%" v-model="conditions" :rules="conditionsRules" required dense
              @change="sendParams()">
            </v-checkbox>

            <label>
              <b>AUTORIZACIÓN PARA TRATAMIENTO DE DATOS PERSONALES:</b> Autorizo que mi información personal sea
              recopilada, almacenada, tratada y divulgada tanto por Southbridge como por la Compañía de Seguros, según la
              normativa vigente y conforme con el Acuerdo de Privacidad contenido en www.southbridgeseguros.cl y las
              políticas propias de la compañía aseguradora.
            </label>

          </div>

          <br>
          <br>

        </v-col>

      </v-form>

    </div>

  </v-container>
</template>

<script>
import RutUtils from "../utils/rut.utils";
// import ShieldService from "../services/shield.service";

import CryptoJS from 'crypto-js';
import swal from 'sweetalert';
import Vue from "vue";

import puntosPrice from "@/assets/images/puntosprice.png";
import FundacionLasRosasLogo from '@/assets/images/FundacionLasRosasLogo.svg';
// import BuinZooLogo from '@/assets/images/BuinZooLogo.svg';
// import CareLogo from '@/assets/images/CareLogo.svg';
// import BomberosLogo from '@/assets/images/BomberosLogo.svg';

import BannerClientesMobile from "@/assets/images/BannerClientesMobile.png";
import BannerClientesDesktop from "@/assets/images/BannerClientesDesktop.png";

import BannerNoClientesMobile from "@/assets/images/BannerNoClientesMobile.png";
import BannerNoClientesDesktop from "@/assets/images/BannerNoClientesDesktop.png";

import BannerColaboradoresMobile from "@/assets/images/BannerColaboradoresyPymeMobile.jpg";
import BannerColaboradoresDesktop from "@/assets/images/BannerColaboradoresyPymeDesktop.jpg";

import BannerPymeMobile from "@/assets/images/BannerPuntosMobile.png";
import BannerPymeDesktop from "@/assets/images/BannerPuntosDesktop.png";



import WebpayLogo from '@/assets/images/webpayLogo.svg';

import { VueReCaptcha } from 'vue-recaptcha-v3'
const { VUE_APP_RECAPTCHA3_SITE_KEY } = process.env;

Vue.use(VueReCaptcha, { siteKey: VUE_APP_RECAPTCHA3_SITE_KEY })

export default {

  name: "PlanForm",
  props: ["carData", "propietarioData", "planData", "servicesData", "arrayShoppingCart", "donationData", "calculateFinalPrice"],
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,
    pathname: window.location.pathname,
    donateOptions: [
      { value: 'Fundación Las Rosas', label: 'Ayuda a mantener los cuidados de las 2.200 personas mayores a lo largo del país.', image: FundacionLasRosasLogo, name: 'LasRosas' },
    ],
    WebpayLogo,
    typePlan: null,
    SummaryBanner: null,
    SummaryBannerMobile: null,
    BannerClientesMobile,
    BannerClientesDesktop,
    BannerNoClientesMobile,
    BannerNoClientesDesktop,
    BannerColaboradoresMobile,
    BannerColaboradoresDesktop,
    BannerPymeMobile,
    BannerPymeDesktop,
    isMobile: window.innerWidth <= 767,
    status: false,
    donate: false,
    screen: window.screen.width,
    typeCliente: null,
    keygen: null,
    priceTotal: 0,
    priceBox: null,
    priceContainer: null,
    selectedItem: null,
    dialogCarBox: null,
    donateTitle: null,
    btnBackView: null,
    btnPayView: null,
    buttonOptionsBox: null,
    summaryBannerTitle: null,
    bannerImage: null,
    buttonGroup: null,
    summaryTitle: null,
    summaryBox: null,
    summaryDataBox: null,
    summaryDataTitle: null,
    summaryPrices: null,
    summaryBanner: null,
    summaryPricesTotal: null,
    donateButton: null,
    donateMessageFirst: null,
    donateMessageSecond: null,
    donateMessageSecondBlack: null,
    noDonarOption: null,
    webpayBox: null,
    webpayTitle: null,
    btnPayBox: null,
    donateBox: null,
    donateMessageBox: null,
    donateMessageBoxBlack: null,
    donateOptionsBox: null,
    radioOption: null,
    donateDescription: null,
    buttonsDonateBox: null,
    donationContainer: null,
    puntosPrice,
    patentFieldView: null,
    patentLabelView: null,
    planId: null,
    type: null,
    token: null,
    pagar: false,
    conditions: null,
    dialog: false,
    valid: false,
    switchFormaPago: null,
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    webPayUrl: process.env.VUE_APP_WEBPAY_URL,
    newRut: "",
    dialogDelete: false,
    dialog: false,
    editedIndex: -1,
    currentPage: 1,
    itemsPerPage: 5,
    editedItem: {
      patenteNumber: '',
      typeVehicle: '',
      modelVehicle: '',
      personEmail: '',
      price: 0,
    },
    defaultItem: {
      patenteNumber: '',
      typeVehicle: '',
      modelVehicle: '',
      personEmail: '',
      price: 0,
    },
    headersPuntos: [
      { text: 'Patente', align: 'start', sortable: false, value: 'carData.patente', align: 'center' },
      { text: 'Tipo de Vehículo', value: 'carData.type', align: 'center', sortable: false },
      { text: 'Modelo', value: 'carData.model', align: 'center', sortable: false },
      { text: 'Correo', value: 'propietarioData.email', align: 'center', sortable: false },
      { text: 'Valor SOAP', value: 'carData.pricePuntos', align: 'center', sortable: false },
      { text: 'Eliminar', value: 'actions', sortable: false, align: 'center' },
    ],
    headers: [
      { text: 'Patente', align: 'start', sortable: false, value: 'carData.patente', align: 'center' },
      { text: 'Tipo de Vehículo', value: 'carData.type', align: 'center', sortable: false },
      { text: 'Modelo', value: 'carData.model', align: 'center', sortable: false },
      { text: 'Correo', value: 'propietarioData.email', align: 'center', sortable: false },
      { text: 'Valor SOAP', value: 'carData.price', align: 'center', sortable: false },
      { text: 'Eliminar', value: 'actions', sortable: false, align: 'center' },
    ],
    confirmRules: [(v) => !!v || "Selecciona una opción"],
    rutRules: [
      (v) => !!v || "Rut es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    rutRules2: [
      (v) => !!v || "Rut del cliente es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    contrasenaRules: [
      (v) => !!v || "La contraseña es requerida",
    ],
    conditionsRules: [
      (v) => !!v || "",
    ]
  }),
  watch: {
    'servicesData.entityDonation'(newValue) {
      if (newValue) {
        this.donate = null;
      }
    },
    donate(newValue) {
      if (newValue === false) {
        this.servicesData.entityDonation = false;
        this.servicesData.finalPrice = this.servicesData.finalPrice - this.servicesData.priceDonation
        this.servicesData.priceDonation = null;
      }
    },
    'servicesData.priceDonation'(newValue, oldValue) {
      if (newValue === null) {
        return;
      }
      if (oldValue != null) {
        this.servicesData.finalPrice -= oldValue;
      }
      this.servicesData.finalPrice += newValue;
    }
  },
  computed: {
    computedHeaders() {
      return this.pathname.includes('puntos') ? this.headersPuntos : this.headers;
    }
  },
  async mounted() {
    this.servicesData.optionDonate = true
    this.servicesData.propietario = 'true';
    this.pagar = false;

    // const query = this.$route.query;

    if (this.$route.path.includes('/general')) {
      this.SummaryBannerMobile = this.BannerNoClientesMobile;
      this.SummaryBanner = this.BannerNoClientesDesktop;

    } else if (this.$route.path.includes('/clientes')) {
      this.SummaryBannerMobile = this.BannerClientesMobile;
      this.SummaryBanner = this.BannerClientesDesktop;

    } else if (this.$route.path.includes('/colaborador')) {
      this.SummaryBannerMobile = this.BannerColaboradoresMobile;
      this.SummaryBanner = this.BannerColaboradoresDesktop;

    } else if (this.$route.path.includes('/puntos')) {
      this.SummaryBannerMobile = this.BannerPymeMobile;
      this.SummaryBanner = this.BannerPymeDesktop;
    }

    if (this.screen > 767) {
      this.patentFieldView = 'patentFieldView';
      this.priceBox = 'priceBox';
      this.priceContainer = 'priceContainer';
      this.dialogCarBox = 'dialogCarBox';
      this.donateBox = 'donateBox';
      this.donateMessageBox = 'donateMessageBox';
      this.donateMessageBoxBlack = 'donateMessageBoxBlack';
      this.donateMessageFirst = 'donateMessageFirst';
      this.donateMessageSecond = 'donateMessageSecond';
      this.donateMessageSecondBlack = 'donateMessageSecondBlack';
      this.radioOption = 'radioOption';
      this.donateOptionsBox = 'donateOptionsBox';
      this.noDonarOption = 'noDonarOption';
      this.buttonOptionsBox = 'buttonOptionsBox';
      this.donateButton = 'donateButton';
      this.donateTitle = 'donateTitle';
      this.buttonGroup = 'buttonGroup';
      this.summaryBox = 'summaryBox';
      this.summaryTitle = 'summaryTitle';
      this.summaryBanner = 'summaryBanner';
      this.bannerImage = 'bannerImage';
      this.summaryBannerTitle = 'summaryBannerTitle';
      this.summaryDataBox = 'summaryDataBox';
      this.summaryPrices = 'summaryPrices';
      this.summaryPricesTotal = 'summaryPricesTotal';
      this.patentLabelView = 'patentLabelView';
      this.btnBackView = 'btnBackView';
      this.btnPayView = 'btnPayView';
      this.summaryDataTitle = 'summaryDataTitle';
      this.donateDescription = 'donateDescription';
      this.webpayBox = 'webpayBox';
      this.webpayTitle = 'webpayTitle';
      this.btnPayBox = 'btnPayBox';
      this.buttonsDonateBox = 'buttonsDonateBox';
      this.donationContainer = 'donationContainer';
      this.hrStyle = 'hrStyle';
      this.hrStyle2 = 'hrStyle2';
    } else if (this.screen <= 767) {
      this.patentFieldView = 'patentFieldView2';
      this.priceBox = 'priceBox2';
      this.priceContainer = 'priceContainerMovil';
      this.dialogCarBox = 'dialogCarBox2';
      this.donateBox = 'donateBox2';
      this.donateMessageBox = 'donateMessageBox2';
      this.donateMessageBoxBlack = 'donateMessageBoxBlack2';
      this.donateMessageFirst = 'donateMessageFirst2';
      this.donateMessageSecond = 'donateMessageSecond2';
      this.donateMessageSecondBlack = 'donateMessageSecondBlack2';
      this.radioOption = 'radioOption2';
      this.donateOptionsBox = 'donateOptionsBox2';
      this.noDonarOption = 'noDonarOption2';
      this.buttonOptionsBox = 'buttonOptionsBox2';
      this.donateButton = 'donateButton2';
      this.donateTitle = 'donateTitle2';
      this.buttonGroup = 'buttonGroup2';
      this.summaryBox = 'summaryBox2';
      this.summaryTitle = 'summaryTitle2';
      this.summaryBanner = 'summaryBanner2';
      this.bannerImage = 'bannerImage2';
      this.summaryBannerTitle = 'summaryBannerTitle2';
      this.summaryDataBox = 'summaryDataBox2';
      this.summaryPrices = 'summaryPrices2';
      this.summaryPricesTotal = 'summaryPricesTotal2';
      this.patentLabelView = 'patentLabelView2';
      this.btnBackView = 'btnBackView2';
      this.btnPayView = 'btnPayView2';
      this.donateDescription = 'donateDescription2';
      this.summaryDataTitle = 'summaryDataTitle2';
      this.webpayBox = 'webpayBox2';
      this.webpayTitle = 'webpayTitle2';
      this.btnPayBox = 'btnPayBox2';
      this.buttonsDonateBox = 'buttonsDonateBox2';
      this.donationContainer = 'donationContainer2';
      this.hrStyle = 'hrStyleMovil';
      this.hrStyle2 = 'hrStyle2Movil';
    }

  },
  methods: {
    clearSelectedButtons() {
      this.servicesData.priceDonation = null;
      this.calculateFinalPrice();
    },
    selectDonation(donation) {
      this.servicesData.priceDonation = donation.amount;
      this.servicesData.secretDonation = donation.secret;
      this.servicesData.optionDonate = false; 
    },
    async setAmount(donation) {
      this.servicesData.priceDonation = donation.amount;
      this.servicesData.secretDonation = donation.secret;
    },
    async obtenerToken() {
      this.pagar = true;
    },
    deleteItem(item) {
      this.selectedItem = item;
      this.editedIndex = this.arrayShoppingCart.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {

      this.arrayShoppingCart.splice(this.editedIndex, 1);

      this.servicesData.priceTotal = this.arrayShoppingCart.reduce((accumulator, item) => accumulator + (item.carData.price || 0), 0);

      const arrayShoppingCartLocal = JSON.stringify(this.arrayShoppingCart);
      const encryptedArrayShoppingCartLocal = CryptoJS.AES.encrypt(arrayShoppingCartLocal, this.secretKey).toString();
      localStorage.setItem('arrayShoppingCart', encryptedArrayShoppingCartLocal);

      const servicesDataLocal = JSON.stringify(this.servicesData);
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
      localStorage.setItem('servicesData', encryptedServicesDataLocal);

      if (this.arrayShoppingCart.length == 0) {
        swal({
          title: "¡No posee SOAP's en su carrito!",
          text: "Será redirigido al inicio de la plataforma",
          icon: "info",
          button: "Ok",
        }).then(() => {
          localStorage.removeItem('carData');
          localStorage.removeItem('planData');
          localStorage.removeItem('propietarioData');
          localStorage.removeItem('servicesData');
          localStorage.removeItem('arrayShoppingCart');
          window.location.href = `/${this.servicesData.typeCliente}/inicio`;
        });
      } else {
        this.closeDelete();
      }

    },
    closeDelete() {
      this.dialogDelete = false;
      this.selectedItem = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      })
    },
    back() {
      this.$emit("back");
    },
    btnBackToPay() {
      window.location.href = `/${this.servicesData.typeCliente}/inicio`
      // this.$router.push({ path: `/${this.servicesData.typeCliente}/inicio` });
    },
    NewCarro() {
      if (this.arrayShoppingCart.length >= 20) {
        swal({
          title: "¡Límite alcanzado!",
          text: "No puedes agregar más elementos al carrito.",
          icon: "info",
          button: "Ok",
        });
        return; // Salir de la función si se alcanza el límite
      } else {
        const agregarOtro_3 = document.getElementById('agregarOtro_3');
        // this.token = null;

        localStorage.removeItem('propietarioData');
        localStorage.removeItem('carData');
        this.servicesData.newCar = true;

        const servicesDataLocal = JSON.stringify(this.servicesData);
        const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
        localStorage.setItem('servicesData', encryptedServicesDataLocal);

        window.location.href = `/${this.servicesData.typeCliente}/inicio`
        // this.$router.push({ path: `/${this.servicesData.typeCliente}/inicio` });
      }
    },
    sendParams() {
      let params = {
        // token: this.token,
        conditions: this.conditions,
      };
      this.$emit("getToken", params);
    },
    formatPrice(number) {
      // console.log('number:', number);
      return this.formatter.format(number);
    },
    async changeRut(rut) {
      this.servicesData.rutPropietario = RutUtils.formatRut(rut);
      this.newRut = RutUtils.formatRut(rut);
    },
  },
};
</script>

<style lang="css" scoped>
@media (max-width: 767px) {

  /* Color de fondo para el header de la columna Patente */
  .v-data-table thead th:nth-child(1) {
    background-color: rgba(227, 233, 253, 1);
    /* Color E3E9FD */
    color: #333333;
    font-weight: bold;
  }

  /* Color de fondo para las celdas de la columna Patente */
  .v-data-table tbody td:nth-child(1) {
    background-color: rgba(227, 233, 253, 1);
    /* Color E3E9FD */
    color: #333333;
    font-weight: bold;
  }
}
.images {
  margin: 0;
  padding: 0;
  display: block;
}
.donationContainer{
  margin-top: 3%;
  margin-bottom: 3%;
  display: flex;
  background-color: rgba(243,243,243,1);
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  padding: 2%;
  width: 50%;
}
.donationContainer2{
  margin-top: 3%;
  margin-bottom: 3%;
  display: flex;
  background-color: rgba(243,243,243,1);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 92%;
  margin: auto;
  padding: 3%;
}

.btnPayBox {
  display: flex;
  align-items: center;
  gap: 4%;
  justify-content: center;
}

.btnPayBox2 {
  display: block;
  align-items: center;
  justify-content: center;
  /* background-color: yellowgreen; */
}

.webpayTitle {
  color: rgba(45, 56, 124, 1);
  font-weight: bold;
  font-size: 24px;
}

.webpayTitle2 {
  color: rgba(45, 56, 124, 1);
  font-weight: bold;
  font-size: 20px;
}

.priceContainerMovil {
  margin: auto;
}

.webpayBox {
  display: flex;
  align-items: center;
  gap: 4%;
  justify-content: center;
}

.webpayBox2 {
  display: block;
  align-items: center;
  justify-content: center;
}

.summaryDataTitle {
  font-size: 20px;
  text-align: left;
  display: block;
  margin-left: 5.2%;
}

.summaryDataTitle2 {
  font-size: 16px;
  text-align: left;
  display: block;
  margin-left: 5.2%;
}

.buttonsDonateBox{
  display: flex;
  gap: 4%;
  justify-content: center;
  align-items: center;
}

.donateDescription{
  width: 100%;
  font-size: 18px;
  color:rgba(51, 51, 51, 1);
  font-family: 'Arial', sans-serif;
  text-align: left;
  padding-left: 3%;
}
.donateDescription2 {
  text-align: left;
  /* background-color: yellowgreen; */
  font-size: 12px;
  margin-top: -2%;
  margin-left: 2%;
}

.btnBackView {
  width: 100%;
  max-width: 200px;
  height: 40px !important;
  background: #2D387C 0% 0% no-repeat padding-box !important;
  /* background: #4F74E3 0% 0% no-repeat padding-box !important; */
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
}

.btnBackView2 {
  width: 90%;
  /* max-width: 200px; */
  height: 40px !important;
  background: #2D387C 0% 0% no-repeat padding-box !important;
  /* background: #4F74E3 0% 0% no-repeat padding-box !important; */
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  margin-bottom: 10%;
}

.btnPayView {
  width: 100%;
  max-width: 200px;
  height: 40px !important;
  /* background: #2D387C 0% 0% no-repeat padding-box !important; */
  background: #4F74E3 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
}

.btnPayView2 {
  width: 90%;
  height: 40px !important;
  /* background: #2D387C 0% 0% no-repeat padding-box !important; */
  background: #4F74E3 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
}

.patentLabelView {
  font-family: 'Arial', sans-serif;
  color: #333333;
  font-size: 14px;
  margin-left: -18%;
}

.patentLabelView2 {
  font-family: 'Arial', sans-serif;
  color: #333333;
  margin-right: 50%;
  font-size: 80%;
}

.summaryPrices {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  gap: 20%;
  margin: auto;
  margin-top: 2%;
  margin-bottom: 2%;
}

.summaryPrices span:first-child {
  flex: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10%;
  text-align: left;
  /* Alinea el precio a la derecha */
}

.summaryPrices span:last-child {
  flex: 1;
  /* Precio ocupa menos espacio */
  text-align: left;
  /* Alinea el precio a la derecha */
  margin-left: 32%;
}

.summaryPrices2 {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  gap: 20%;
  margin: auto;
  margin-top: 2%;
  margin-bottom: 2%;
}

.summaryPrices2 span:first-child {
  flex: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10%;
  text-align: left;
  /* Alinea el precio a la derecha */
}

.summaryPrices2 span:last-child {
  flex: 1;
  /* Precio ocupa menos espacio */
  text-align: left;
  /* Alinea el precio a la derecha */
  margin-left: -10%;
}

.summaryPricesTotal {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  gap: 20%;
  margin: auto;
  margin-top: 2%;
  margin-bottom: 2%;
}

.summaryPricesTotal span:first-child {
  flex: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10%;
  text-align: left;
  /* Alinea el precio a la derecha */
  font-weight: bold;
}

.summaryPricesTotal span:last-child {
  flex: 1;
  /* Precio ocupa menos espacio */
  text-align: right;
  /* Alinea el precio a la derecha */
  margin-right: 42%;
  font-weight: bold;
}

.summaryPricesTotal2 {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  /* gap: 15%;  */
  margin: auto;
  margin-top: 2%;
  margin-bottom: 2%;
  position: relative;
}

.summaryPricesTotal2 span:first-child {
  flex: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10%;
  text-align: left;
  font-weight: bold;
}

.summaryPricesTotal2 span:last-child {
  position: absolute;
  right: 7.2%;
  font-weight: bold;
}

.summaryDataBox {
  background-color: white;
  width: 85%;
  margin: auto;
  border-radius: 6px;
  margin-top: 2%;
}

.summaryDataBox2 {
  background-color: white;
  width: 85%;
  margin: auto;
  border-radius: 6px;
  margin-top: 2%;
}

.bannerImage {
  padding: 2%;
  max-width: 95%;
  margin: auto;
  border-radius: 16px;
}

.bannerImage2 {
  padding: 4%;
  max-width: 90%;
  margin: auto;
  margin-top: -5%;
  margin-bottom: 5%;
  border-radius: 16px;
}

.summaryBanner {
  background-color: white;
  width: 85%;
  margin: auto;
  border-radius: 6px;
  margin-top: 2%;
}

.summaryBanner2 {
  background-color: white;
  width: 85%;
  margin: auto;
  border-radius: 6px;
}

.summaryBox {
  background-color: rgba(231,233,235,1);
  border-radius: 6px;
  width: 75%;
  margin: auto;
}

.summaryBox2 {
  background-color: rgba(231,233,235, 1);
  border-radius: 6px;
  width: 100%;
  margin: auto;
}

.summaryBannerTitle {
  font-family: 'Arial', sans-serif;
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  font-size: 22px;
}

.summaryTitle {
  font-family: 'Arial', sans-serif;
  color: rgba(77,77,77, 1);
  font-weight: bold;
  font-size: 20px;
}

.summaryTitle2 {
  font-family: 'Arial', sans-serif;
  color: rgba(77,77,77, 1);
  font-weight: bold;
  font-size: 16px;
}

.buttonOptionsBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-bottom: 3%;
}

.donateTitle {
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
}

.donateTitle2 {
  font-size: 14px;
  color: rgba(42, 56, 129, 1);
  /* margin-bottom: 10px;  */
  font-weight: 600;
  text-align: center;
}

.buttonGroup {
  display: flex;
  gap: 15px;
  margin-bottom: 3%;
}

.buttonGroup2 {
  display: flex;
  justify-content: center;
  width: 100%;
}

.donateButton {
  background-color: white;
  color: rgba(0,105,185, 1);
  border: solid 1px rgba(0,105,185, 1);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  width: 200px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.donateButton2 {
  background-color: white;
  color: rgba(0,105,185, 1);
  border: solid 1px rgba(0,105,185, 1);
  padding: 4px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 85px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  margin: 2%;
}

.donateButton.selected {
  background-color: rgba(0,105,185, 1);
  font-weight: bold;
  color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.donateButton2.selected {
  background-color:rgba(0,105,185, 1);
  font-weight: bold;
  color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.donateOptionsBox {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 10px;
}

.noDonarOption {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
  text-align: left;
}

.noDonarOption2 {
  display: flex;
  padding-top: 4%;
  padding-bottom: 4%;
  align-items: center;
  margin-top: 10px;
  text-align: left;
  background-color: #F1F1F1;
  flex-direction: row-reverse;
  border-bottom: 1px solid #D5D5D5;
}

.noDonarOption2 span {
  color: rgba(42, 56, 129, 1);
  font-size: 13px !important;
  margin-right: 5%;
  margin-left: 2%;
  font-weight: 600 !important;
  width: 100%;
}

.noDonarOption2 input[type="radio"] {
  margin-right: 2.5%;
}

.radioOption {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

.radioOption2 {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  gap: 2%;
  padding: 2%;
}

.donateBox {
  width: 100%;
  border: 1px solid grey;
  text-align: center;
  justify-content: center;
  padding: 2%;
  border-radius: 6px;
}

.donateBox2 {
  width: 100%;
  border: 1px solid grey;
  text-align: center;
  justify-content: center;
  border-radius: 6px;
}

.donateMessageBox {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.donateMessageBox2 {
  width: 100%;
}

.donateMessageBoxBlack {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.donateMessageBoxBlack2 {
  width: 94%;
  margin: auto;
}

.donateMessageFirst {
  font-family: 'Arial', sans-serif;
  color: rgba(0,105,185, 1);
  font-weight: bold;
  font-size: 20px;
}

.donateMessageFirst2 {
  font-family: 'Arial', sans-serif;
  color: rgba(40,40,40,1);
  font-weight: 500 !important;
  font-size: 14px;
}

.donateMessageSecond {
  margin-left: 0.6%;
  font-family: 'Arial', sans-serif;
  color: rgba(45, 85, 112, 1);
  font-weight: bold;
  font-size: 20px;
}

.donateMessageSecond2 {
  margin-left: 0.6%;
  font-family: 'Arial', sans-serif;
  color: rgba(40,40,40,1);
  font-weight: 500 !important;
  font-size: 14px;

}

.donateMessageSecondBlack {
  margin-left: 0.6%;
  font-family: 'Arial', sans-serif;
  color: rgba(45, 85, 112, 1);
  font-weight: bold;
  font-size: 20px;
}

.donateMessageSecondBlack2 {
  margin-left: 0.6%;
  font-family: 'Arial', sans-serif;
  color: rgba(40,40,40,1);
  font-weight: bold;
  font-size: 14px;
}

.dialogCarBox {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-top: 2%;
}

.titulo {
  color: rgb(126, 43, 137);
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0px;
  font-family: 'Arial', sans-serif;
  text-align: center;
  line-height: 1.2;
}

.subtitulo {
  font-family: 'Arial', sans-serif;
  width: 280px;
  text-align: center;
  margin: 15px auto auto;
  line-height: 1.2;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.imageCenco {
  background-image: url("~@/assets/images/CencoImage.png");
  background-position: center;
  background-size: cover;
  display: flex;
}

.login {
  display: inline-block;
  box-sizing: border-box;
  border-radius: 0.3rem;
  background-color: rgb(19, 65, 105);
  height: 40px;
  line-height: 40px;
  width: 100%;
  padding: 0px 1.5rem;
  cursor: pointer;
  color: white;
  font-weight: 600;
  margin: auto;
}

#app>div.v-dialog__content.v-dialog__content--active>div>div>div>div>div:nth-child(1)>button {
  height: 36px;
  min-width: 64px;
  padding: 0 16px;
  background-color: rgb(19, 65, 105);
}

.precio {
  height: 40px;
  text-align: center;
  font: Arial;
  font-size: 30px;
  letter-spacing: 0.53px;
  color: rgba(51, 51, 51, 1);
  opacity: 1;
  margin: auto;
}

.patenteNumber {
  width: 138px;
  height: 40px;
  text-align: center;
  font: Arial;
  font-size: 35px;
  letter-spacing: 0.53px;
  color: #4E4E4E;
  text-transform: uppercase;
  opacity: 1;
}

.valor {
  background-color: rgba(0,105,185, 1);
  color: #FFF;
  font-family: 'Arial', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.24px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 2%;
  padding-bottom: 2%;
}

.condicion {
  width: 56px;
  height: 21px;
  text-align: center;
  font: Arial;
  font-size: 14px;
  letter-spacing: 0.26px;
  color: #0069B6;
  text-transform: uppercase;
  opacity: 1;
}

.patente {
  width: 76px;
  height: 21px;
  font: Arial;
  font-size: 17px;
  letter-spacing: 0.26px;
  color: #4E4E4E;
  text-transform: uppercase;
  opacity: 1;
}

.webpay {
  width: 80%;
  height: 206px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
}

.cenco {
  width: 80%;
  height: 206px;
  background: #753783 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
}

.rectangulo {
  box-shadow: 0px 0px 6px #00000029;
  max-width: 420px;
  opacity: 1;
  margin: auto;
  border-radius: 10px;

}

.hrStyle {
  width: 147px;
  height: 0px;
  border: 1px solid #D0D0D0;
  opacity: 1;
  margin: auto;
}

.hrStyle2 {
  width: 139px;
  height: 0px;
  border: 1px solid #D0D0D0;
  opacity: 1;
  margin: auto;
}

.hrStyleMovil {
  width: 69px;
  height: 0px;
  border: 1px solid #D0D0D0;
  opacity: 1;
  margin: auto;
}

.hrStyle2Movil {
  width: 69px;
  height: 0px;
  border: 1px solid #D0D0D0;
  opacity: 1;
  margin: auto;
}

.question {
  color: rgba(0,105,185, 1);
  font-family: 'Arial', sans-serif;
  margin-right: 1%;
  font-size: 20px;
}

@media (min-width: 767px) and (max-width: 1000px) {
  .question {
    display: block;
  }
}


.button3 {
  width: 270px;
  height: 50px !important;
  background: none !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px Arial;
  letter-spacing: 0.66px;
  color: rgba(0,105,185, 1) !important ;
  border: 1px solid rgba(0,105,185, 1);
  opacity: 1;
  margin-left: 1%;
  font-weight: bold;
}

.button2 {
  width: 270px;
  height: 50px !important;
  background-color: rgba(255,125,0,1) !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px Arial;
  letter-spacing: 0.66px;
  color: white;
  opacity: 1;
  margin-left: 4%;
  font-weight: bold;

}

.finalBox {
  margin-left: -4%;
}

.title {
  color: rgba(0,105,185, 1);
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  letter-spacing: -0.36px !important;
}

.labelName {
  text-align: left;
  font: Arial;
  font-size: 12px;
  letter-spacing: 0px;
  color: #333;
  opacity: 1;
  font-weight: bold;
  margin-left: -72%;
  font-family: 'Arial', sans-serif;
  letter-spacing: -0.2px;
  line-height: 38.5px;
}

.checkbox {
  max-width: 540px;
  margin: auto;
  color: #333;
  font-family: 'Arial', sans-serif;
  letter-spacing: -0.2px;
  display: flex;
  text-align: left;
  font-size: 13px;
  margin-bottom: -3%;
}

.payTitleImage {
  color: #2D387C;
  display: flex;
  justify-content: center;
}

.payButtons {
  display: flex;
  justify-content: center;
}

.pay {
  background-color: #EFEFEF;
  /* margin-left: -20%; */
  /* max-width: x100%; */
  width: 100vw;
  position: absolute;
  left: 50%;
  margin-left: -200px;
}

.payTitle {
  font-size: 24px;
  margin-top: 0.5%;
}

.payImage {
  margin-left: 2%;
}

.btnBack {
  width: 270px;
  height: 50px !important;
  background-color: #2D387C !important;
  border-radius: 8px !important;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px Arial;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;

  font-weight: bold;
}

.btnPay {
  width: 260px;
  height: 50px !important;
  background-color: #4F74E3 !important;
  border-radius: 8px !important;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px Arial;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  margin-left: 6%;
  font-weight: bold;
}

.tableHeaders {
  background-color: rgba(45, 85, 112, 1) !important;
  color: white !important;
  border: 2px solid rgba(45, 85, 112, 1);
}

.text-center {
  text-align: center;
}

.table {
  border: 1px solid #D5D5D5;
  margin-top: -2%;
  margin-bottom: -1%;
}

.btnClosePopUp {
  background-color: none;
  border: 1px solid rgba(0,105,185, 1);
  color: rgba(0,105,185, 1);
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 0.8px;
  width: 30%;
  margin-top: -2%;
}

.btnClosePopUp2 {
  height: 40px !important;
  background: none !important;
  border: 1px solid rgba(0,105,185, 1);
  color: rgba(0,105,185, 1);
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 0.8px;
  width: 100%;
  margin-top: -2%;
}

.btnConfirmPopUp {
  background-color: rgba(252, 0, 27, 1);
  color: #FFF;
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 0.8px;
  width: 30%;
  margin-top: -2%;
}

.btnConfirmPopUp2 {
  height: 40px !important;
  background-color: rgba(252, 0, 27, 1);
  color: #FFF;
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 0.8px;
  width: 100%;
  margin-top: -2%;
}

.title1 {
  color: rgba(0,105,185, 1);
  font-size: 22px;
}

.title1Mobile {
  color: rgba(0,105,185, 1);
  font-size: 20px;
}

.title2 {
  color: rgba(0,105,185, 1);
  /* margin-left: 1%; */
  margin-right: 1%;
  font-size: 22px;

}

.titleBox {
  width: 100%;
  text-align: center;
}

.titleBoxMobile {
  width: 100%;
  margin: auto;
  text-align: center;
  margin-top: 1%;
  margin-bottom: 3%;
}

.btnBoxMobile {
  display: block;
  width: 70%;
}

.patentFieldView {
  width: 300px;
  height: 50px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  text-transform: uppercase;
  margin: auto;
}

.patentFieldView2 {
  width: 82%;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  text-transform: uppercase;
  margin: auto;
}

.priceBox {
  display: flex;
  margin-top: -2%;
}

.priceBox2 {
  display: flex;
  width: 100%;
  text-align: center;
  margin: auto;
}
</style>

<style lang="css">
.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  background-color: #2D387C;
  color: white !important;
}

.space {
  margin-bottom: 10% !important;
}

.spaceMobile {
  margin-bottom: 4% !important;
}

.trash {
  color: red !important;

}

.radioOption input[type="radio"] {
  transform: scale(1.5);
  margin: 5px;
}
.donateBox {
  width: 100%;
  border: 1px solid grey;
  text-align: center;
  justify-content: center;
  padding: 2%;
  border-radius: 6px;
}
.donateBox2 {
  width: 100%;
  border: 1px solid grey;
  text-align: center;
  justify-content: center;
  border-radius: 6px;
}
.spaceMobile {
  margin-bottom: 4% !important;
}

@media (min-width: 1919px) {
  .patentLabelView {
    font-family: 'Arial', sans-serif;
    color: #333333;
    font-size: 14px;
    margin-left: -14% !important;
  }
}

.v-btn:before {
    background-color: transparent !important;
}
</style>