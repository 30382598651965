import { render, staticRenderFns } from "./SecForm.vue?vue&type=template&id=32ea5356&scoped=true"
import script from "./SecForm.vue?vue&type=script&lang=js"
export * from "./SecForm.vue?vue&type=script&lang=js"
import style0 from "./SecForm.vue?vue&type=style&index=0&id=32ea5356&prod&lang=css&scoped=true"
import style1 from "./SecForm.vue?vue&type=style&index=1&id=32ea5356&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32ea5356",
  null
  
)

export default component.exports